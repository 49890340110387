import { useState, useCallback } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { W3IDropZone } from "./W3IDropZone";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import yaml from "js-yaml";
import { W3W3iForcesChunk as ForcesChunk, W3W3iPlayersChunk as PlayersChunk, W3W3i } from 'backend-api';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTree } from '@table-library/react-table-library/tree';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import './tabs-style.css';
import { Table } from "@table-library/react-table-library";
import { W3I } from "./dependency/W3I";
import { Context } from "./Context";
import { jsonStringifyWithContext, reduceValueObject } from "./ContextUtil";
import { JSONView, YAMLView } from "./BinaryFileResultView";

export const W3IView = () => {
  const [data, setData] = useState<W3I>();

  const handleResponse = useCallback((response: W3I) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3I
      <W3IDropZone onResponse={handleResponse} />
      {/* {text && <pre style={{textAlign: 'left'}}>{JSON.stringify(JSON.parse(text), null, 2)}</pre>} */}
      {data && <W3IResult data={data} />}
    </div>
  );
}

type W3IResultProps = {
  data: W3I;
  context?: Context;
}

export const W3IResult = ({data: dataRaw, context}: W3IResultProps) => {
  const data = dataRaw as W3W3i & {
    description: {
      value: string;
    };
  };

  const [applyContext, setApplyContext] = useState<boolean>(true);

  const resolveString = useCallback((str: string | object | undefined) => {
    return '';
    /*str = reduceValueObject(str);
    if (str === undefined) {
      return '';
    }
    if (str !== undefined && str !== null && typeof str === 'object') { 
      str = `${str}`;
    }
    return context?.resolveString(str);*/
  }, [context?.resolveString]);

  return (
    <Tabs>
      <TabList style={{
          display: 'flex',
          margin: 0,
          marginBlock: 0,
          paddingInline: 0
        }}
      >
        <Tab>JSON</Tab>
        <Tab>YAML</Tab>
        <Tab>Visual</Tab>
      </TabList>

      <TabPanel>
        <JSONView data={data} context={context} />
      </TabPanel>
      <TabPanel>
        <YAMLView data={data} context={context} />
      </TabPanel>
      <TabPanel>
        <label htmlFor="applyContext">Resolve Context</label>
        <input id="applyContext" type="checkbox" checked={applyContext} onChange={(event) => setApplyContext(event.target.checked)} />
        <div style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          textAlign: 'left',
          margin: '1em',
          gap: '0.5em'
        }}>
          <span>Map Name: {resolveString(data.mapName)}</span>
          <span>Saves: {data.saves}</span>
          <span>Supported Graphic Modes: {'HD: '}{renderBoolean(data.supportedGraphicsModes?.hd)} {'SD: '}{renderBoolean(data.supportedGraphicsModes?.sd)}</span>
          <span>Editor Version: {data.editorVersion}</span>
          <span>Author: {resolveString(data.author)}</span>
          <span>Description: {resolveString(data.description)}</span>
          <span>Recommended Players: {resolveString(data.recommendedPlayers)}</span>
          <span style={{textTransform: 'capitalize'}}>Script Language: {data.scriptLanguage?.toLowerCase()}</span>
          <span>Light Environment: {data.lightEnvironment?.value === '\0' ? 'None' : data.lightEnvironment?.value}</span>
          <span style={{display: 'flex', alignItems: 'center'}}><span>Water Color: {data.waterColor?.red} {data.waterColor?.green} {data.waterColor?.blue} {data.waterColor?.alpha}</span>{data.waterColor && renderColor(data.waterColor)}</span>
          <span>Sound Environment: {data.soundEnvironment?.value}</span>
          <span>Game Version: {data.gameVersion?.major}.{data.gameVersion?.minor}.{data.gameVersion?.revision}.{data.gameVersion?.build}</span>
          <span>Map Dimensions: {data.mapWidth} x {data.mapHeight}</span>
          <span style={{textTransform: "capitalize"}}>Tileset: {data.tileset?.replace("_", " ").toLowerCase()}</span>
          <span style={{display: 'flex', alignItems: 'center'}}>
            <span>Fog: <span style={{textTransform: 'capitalize'}}>{data.fog?.fogType?.toLocaleLowerCase()}</span> {data.fog?.fogDensity?.toFixed(2) ?? 0} {data.fog?.fogZStart} {data.fog?.fogZEnd} {data.fog?.fogColorRed} {data.fog?.fogColorGreen} {data.fog?.fogColorBlue} {data.fog?.fogColorAlpha}</span>
            {data.fog && renderColor({red: data.fog?.fogColorRed, green: data.fog?.fogColorGreen, blue: data.fog?.fogColorBlue, alpha: data.fog?.fogColorAlpha})}
          </span>
          <span>Script Language2: {data.scriptLanguage2}</span>
          <span>Global Weather Id: {data.globalWeatherId?.value === '\0\0\0\0' ? 'None' : data.globalWeatherId?.value}</span>
          <span>Margins: {data.margins?.bottom} {data.margins?.left} {data.margins?.right} {data.margins?.top}</span>
          <span style={{textTransform: 'capitalize'}}>Game Data Set: {data.gameDataSet?.toLowerCase()}</span>
          <span>Game Data Version: {data.gameDataVersion}</span>
          {/* <FlagsTable data={data} /> */}
          <span>{`Loading Screen: ${data.loadingScreen?.customLoadingScreenPath?.value} ${data.loadingScreen?.loadingScreenIndex === -1 ? 'None' : data.loadingScreen?.loadingScreenIndex} ${data.loadingScreen?.loadingScreenTitle?.value} ${data.loadingScreen?.loadingScreenSubtitle?.value} ${data.loadingScreen?.loadingScreenText?.value}`}</span>
          <span>{`Prologue Screen: ${data.prologueScreen?.prologueScreenPath?.value} ${data.prologueScreen?.prologueScreenTitle?.value} ${data.prologueScreen?.prologueScreenSubtitle?.value} ${data.prologueScreen?.prologueScreenText?.value}`}</span>
          <span>{`Camera Bounds: bottom left: (${data.cameraBounds?.cameraBoundBottomLeft?.x}, ${data.cameraBounds?.cameraBoundBottomLeft?.y}) top right: (${data.cameraBounds?.cameraBoundTopRight?.x}, ${data.cameraBounds?.cameraBoundTopRight?.y}) top left: (${data.cameraBounds?.cameraBoundTopLeft?.x}, ${data.cameraBounds?.cameraBoundTopLeft?.y}) bottom right: (${data.cameraBounds?.cameraBoundBottomRight?.x}, ${data.cameraBounds?.cameraBoundBottomRight?.y})`}</span>
          {/* <PlayerTable data={data} context={context} /> */}
          <ForceTable data={data} resolveString={resolveString} />
        </div>
      </TabPanel>
    </Tabs>
  );
}

const FlagsTable = ({data}: {data: W3W3i}) => {
  type FlagNode = {
    id: string;
    name: string;
    value: boolean;
  };
  const nodes: FlagNode[] | undefined = Object.entries(data.flags ?? []).map((entry, index) => ({
    id: index.toString(),
    name: entry[0],
    value: entry[1]
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Flag', renderCell: (flag: FlagNode) => flag.name },
    { label: 'Value', renderCell: (flag: FlagNode) => renderBoolean(flag.value) }
  ];

  const theme = useTheme(getTheme());

  const treeData = {nodes};

  const tree = useTree(treeData, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const PlayerTable = ({data, context}: {data: W3W3i, context?: Context}) => {
  type PlayerNode = {
    id: string;
  } & NonNullable<PlayersChunk['player']>[number];
  const nodes: PlayerNode[] | undefined = data.playersChunk?.player?.map((player, index) => ({
    id: index.toString(),
    ...player
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Num', renderCell: (player: PlayerNode) => player.num },
    {
      label: 'Name',
      renderCell: (player: PlayerNode) => context?.resolveString(player.name?.value ?? ''),
    },
    { label: 'Race', renderCell: (player: PlayerNode) => <span style={{textTransform: 'capitalize'}}>{player.race?.replaceAll('_', ' ').toLocaleLowerCase()}</span> },
    {
      label: 'Fixed Position',
      renderCell: (player: PlayerNode) => renderBoolean(!!player.fixedPosition),
    },
    { label: 'Controller', renderCell: (player: PlayerNode) => <span style={{textTransform: 'capitalize'}}>{player.controller?.toLocaleLowerCase()}</span> },
  ];

  const theme = useTheme(getTheme());

  const treeData = {nodes};

  const tree = useTree(treeData, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const ForceTable = ({data, resolveString}: {data: W3W3i, resolveString: (str: string | object | undefined) => string}) => {
  type ForceNode = {
    id: string;
  } & NonNullable<ForcesChunk['force']>[number];
  const nodes: ForceNode[] | undefined = data.forcesChunk?.force?.map((force, index) => ({
    id: index.toString(),
    ...force
  })) ?? [];
  
  const COLUMNS: {label: string; renderCell: (param: any) => any}[] = [
//    { label: 'Name', renderCell: (force: ForceNode) => resolveString(force.name) },
   // {
  //    label: 'Allied',
   //   renderCell: (force: ForceNode) => renderBoolean(force.flags?.allied),
   // },
   // { label: 'Allied Victory', renderCell: (force: ForceNode) => renderBoolean(force.flags?.alliedVictory) },
    //{
   //   label: 'Share Advanced Unit Control',
  //    renderCell: (force: ForceNode) => renderBoolean(force.flags?.shareAdvancedUnitControl),
 //   },
 //   { label: 'Share Unit Control', renderCell: (force: ForceNode) => renderBoolean(force.flags?.shareUnitControl) },
 //   { label: 'Share Vision', renderCell: (force: ForceNode) => renderBoolean(force.flags?.shareVision) },
//    { label: 'Players', renderCell: (force: ForceNode) => force.players?.isMember?.map((included, index) => included ? index : undefined).filter(index => index).join(',') },
  ];

  const theme = useTheme({
    Table: `
        --data-table-library_grid-template-columns: 11% 10% 10% 10% 10% 10% 39%;
      `,
  });

  const treeData = {nodes};

  const tree = useTree(treeData, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} layout={{ custom: true }} />;
};

const renderBoolean = (val: boolean | undefined) => {
  if (val) {
    return <span style={{
      color: 'green',
      fontWeight: 'bold'
    }}>{"\u2713"}</span>;
  }

  return <span style={{
    color: 'red',
    fontWeight: 'bold'
  }}>{"\u2716"}</span>;
};

const renderColor = ({red, green, blue, alpha}: {red?: number; green?: number; blue?: number; alpha?: number}) => {
  return <span style={{marginLeft: "0.25em", width: "0.75em", height: "0.75em", border: "2px solid black", backgroundColor: `rgb(${red ?? 0}, ${green ?? 0}, ${blue ?? 0})`}}></span>;
}