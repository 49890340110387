import { useState, useCallback } from "react";
import { WCTDropZone } from "./WCTDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { WCT } from "./dependency/WCT";

export const WCTView = () => {
  const [data, setData] = useState<WCT>();

  const handleResponse = useCallback((response: WCT) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      WCT
      <WCTDropZone onResponse={handleResponse} />
      {data && <WCTResult data={data} />}
    </div>
  );
}

export const WCTResult = ({data}: {data: WCT}) => {
  return <BinaryFileResultView data={data} />
}