import { SimpleSLK } from "backend-api";
import { useMemo } from "react";
import { Spreadsheet } from "react-spreadsheet"

export const SlkView = ({data}: {data: SimpleSLK}) => {
    const fields = data.fields;
    const objs = data.objs;
    const fieldSheetData = useMemo(() => [fields?.map(field => ({
        value: field
    })) ?? []], [fields]);
    const objSheetData = useMemo(() => objs?.map(obj => fields?.map(field => ({
            value: obj.fieldToValue ? obj.fieldToValue[field] : ''
        })) ?? []) ?? [], [fields, objs]);
    const sheetData = useMemo(() => fieldSheetData.concat(objSheetData ?? []), [fieldSheetData, objSheetData]);
    return <Spreadsheet data={sheetData} />
}

export const SlkViewText = ({data}: {data: string}) => {
    return <div style={{
        whiteSpace: 'pre-line',
        textAlign: 'left'
    }}>{data}</div>
}