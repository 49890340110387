import { useState, useCallback } from "react";
import { W3UDropZone } from "./W3UDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { W3U } from "./dependency/W3U";
import { W3W3u } from "backend-api";
import { ObjModTabbedView } from "./ObjModTabbedView";
import { Context } from "./Context";

export const W3UView = () => {
  const [data, setData] = useState<W3U>();

  const handleResponse = useCallback((response: W3U) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3U
      <W3UDropZone onResponse={handleResponse} />
      {data && <W3UResult data={data} />}
    </div>
  );
}

export const W3UResult = ({data: dataRaw, context}: {data: W3U, context?: Context}) => {
  const data = dataRaw as W3W3u;
  return <ObjModTabbedView data={data} context={context} />
}