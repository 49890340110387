import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { RootContext } from "./dependency/txtParser";
import { parseTxt } from "./TxtParseUtil";
import { readBuilderProgram } from "typescript";

type TXTDropZoneProps = {
  onResponse: (val: RootContext) => void;
}

export const TXTDropZone = ({onResponse}: TXTDropZoneProps) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    const reader = new FileReader();
    const text = reader.readAsText(acceptedFiles[0], 'utf-8');
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const tree = parseTxt(reader.result);
        onResponse(tree);
      } else {
        console.error('wrong type of result', reader.result);
      }
    };
    reader.onerror = () => {
      console.error(reader.error);
    };
  }, [onResponse])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} style={{
      border: '1px dashed grey'
    }}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the TriggerData.txt file here ...</p> :
          <p>Drag 'n' drop the TriggerData.txt file here, or click to select the file</p>
      }
    </div>
  )
}