import { W3Imp } from "backend-api"
import Spreadsheet from "react-spreadsheet";

export const IMPResult = ({data}: {data: W3Imp}) => {
  const firstObj = data.importObj?.[0];
  const fieldSheetData = [Object.entries(firstObj ?? {}).map(entry => ({value: `${entry[0]}`} ?? '')) ?? []];
  const objSheetData = data.importObj?.map(obj => Object.entries(obj).map(entry => ({value: resolve(entry[1])} ?? '')) ?? []) ?? [];
  const sheetData = fieldSheetData.concat(objSheetData);
  return <Spreadsheet data={sheetData} />
}

const resolve = (data: any) => {
  if (data !== null && typeof data  === 'object') {
    if (typeof data.value === 'string') {
      return data.value;
    }
  }
  return `${data}`;
}