import { useState, useCallback, useMemo } from "react";
import { MAP } from "./dependency/MAP";
import { MapDropZone } from "./MapDropZone";
import { W3Map } from 'backend-api';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { W3IResult } from "./W3IView";
import { DOOResult } from "./DooView";
import { DOOUnitsImageResult, DooUnitsResult } from "./DooUnitsView";
import { W3AResult } from "./W3AView";
import { WPMResult } from "./WPMView";
import { W3EResult } from "./W3EView";
import { WCTResult } from "./WCTView";
import { W3CResult } from "./W3CView";
import { W3RResult, W3RVisualResult } from "./W3RView";
import { SHDResult } from "./SHDView";
import { MMPVisualResult } from "./MMPView";
import { W3SResult } from "./W3SView";
import { JResult } from "./JView";
import { WTSResult } from "./WTSView";
import { W3BResult } from "./W3BView";
import { W3DResult } from "./W3DView";
import { W3HResult } from "./W3HView";
import { W3QResult } from "./W3QView";
import { W3TResult } from "./W3TView";
import { W3UResult } from "./W3UView";
import { IMPResult } from "./IMPView";
import { SlkView } from "./SlkView";
import { resolveWtsString } from "./ContextUtil";
import { parseWts } from "./WtsParseUtil";
import { MiscResult } from "./MiscView";
import { SkinResult } from "./SkinView";
import { ExtraResult } from "./ExtraView";

export const MapView = () => {
  const [data, setData] = useState<MAP>();

  const handleResponse = useCallback((response: MAP) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      Map
      <MapDropZone onResponse={handleResponse} />
      {data && <MapResultView data={data} />}
    </div>
  );
}

const MapResultView = ({data: dataRaw}: {data: MAP}) => {
  const data = dataRaw as W3Map & {
    extra: string;
  };
  const wtsTree = useMemo(() => data.wts ? parseWts(data.wts) : undefined, [data.wts]);
  const context = useMemo(() => ({resolveString: (str: string) => resolveWtsString(wtsTree, str)}), []);
  return <>
      <Tabs>
        <TabList style={{
          display: 'flex',
          margin: 0,
          marginBlock: 0,
          paddingInline: 0
        }}>
          {data.w3i && <Tab>
            W3I
          </Tab>}
          {data.doo && <Tab>
            Doo
          </Tab>}
          {data.dooUnits && <Tab>
            DooUnits
          </Tab>}
          {data.w3u && <Tab>
            W3U
          </Tab>}
          {data.w3t && <Tab>
            W3T
          </Tab>}
          {data.w3b && <Tab>
            W3B
          </Tab>}
          {data.w3d && <Tab>
            W3D
          </Tab>}
          {data.w3a && <Tab>
            W3A
          </Tab>}
          {data.w3h && <Tab>
            W3H
          </Tab>}
          {data.w3q && <Tab>
            W3Q
          </Tab>}
          {data.w3c && <Tab>
            W3C
          </Tab>}
          {data.w3r && <Tab>
            W3R
          </Tab>}
          {data.w3s && <Tab>
            W3S
          </Tab>}
          {data.mmp && <Tab>
            MMP
          </Tab>}
          {data.shd && <Tab>
            SHD
          </Tab>}
          {data.wpm && <Tab>
            WPM
          </Tab>}
          {data.w3e && <Tab>
            W3E
          </Tab>}
          {data.wct && <Tab>
            WCT
          </Tab>}
          {data.j && <Tab>
            J
          </Tab>}
          {data.wts && <Tab>
            WTS
          </Tab>}
          {data.misc && <Tab>
            Misc
          </Tab>}
          {data.skin && <Tab>
            Skin
          </Tab>}
          {data.extra && <Tab>
            Extra
          </Tab>}
          {data.imp && <Tab>
            IMP
          </Tab>}
          {data.nameToSlk && Object.entries(data.nameToSlk).length > 0 && Object.entries(data.nameToSlk).map(slkEntry => <Tab>
            {slkEntry[0]}
          </Tab>)}
        </TabList>
        {data.w3i && <TabPanel>
          <W3IResult data={data.w3i} context={context} />
        </TabPanel>}
        {data.doo && <TabPanel>
          <DOOResult data={data.doo} width={data.w3e?.mapWidth!} height={data.w3e?.mapHeight!} offsetX={data.w3e?.centerOffsetX!} offsetY={data.w3e?.centerOffsetY!} />
        </TabPanel>}
        {data.dooUnits && <TabPanel>
          <DOOUnitsImageResult data={data.dooUnits} width={data.w3e?.mapWidth!} height={data.w3e?.mapHeight!} offsetX={data.w3e?.centerOffsetX!} offsetY={data.w3e?.centerOffsetY!} />
        </TabPanel>}
        {data.w3u && <TabPanel>
          <W3UResult data={data.w3u} context={context} />
        </TabPanel>}
        {data.w3t && <TabPanel>
          <W3TResult data={data.w3t} context={context} />
        </TabPanel>}
        {data.w3b && <TabPanel>
          <W3BResult data={data.w3b} context={context} />
        </TabPanel>}
        {data.w3d && <TabPanel>
          <W3DResult data={data.w3d} context={context} />
        </TabPanel>}
        {data.w3a && <TabPanel>
          <W3AResult data={data.w3a} context={context} />
        </TabPanel>}
        {data.w3h && <TabPanel>
          <W3HResult data={data.w3h} context={context} />
        </TabPanel>}
        {data.w3q && <TabPanel>
          <W3QResult data={data.w3q} context={context} />
        </TabPanel>}
        {data.w3c && <TabPanel>
          <W3CResult data={data.w3c} />
        </TabPanel>}
        {data.w3r && <TabPanel>
          <W3RVisualResult data={data.w3r} width={data.w3e?.mapWidth!} height={data.w3e?.mapHeight!} offsetX={data.w3e?.centerOffsetX!} offsetY={data.w3e?.centerOffsetY!} />
        </TabPanel>}
        {data.w3s && <TabPanel>
          <W3SResult data={data.w3s} />
        </TabPanel>}
        {data.mmp && <TabPanel>
          <MMPVisualResult data={data.mmp} width={(data.w3i?.mapWidth! + data.w3i?.margins?.left! + data.w3i?.margins?.right!) * 4 ?? 512} height={(data.w3i?.mapHeight! + data.w3i?.margins?.top! + data.w3i?.margins?.bottom!) * 4 ?? 512} offsetX={data.w3i?.margins?.left! + data.w3i?.margins?.right!} offsetY={data.w3i?.margins?.top! + data.w3i?.margins?.bottom!} />
        </TabPanel>}
        {data.shd && <TabPanel>
          <SHDResult data={data.shd} width={(data.w3i?.mapWidth! + data.w3i?.margins?.left! + data.w3i?.margins?.right!) * 4 ?? 512} height={(data.w3i?.mapHeight! + data.w3i?.margins?.top! + data.w3i?.margins?.bottom!) * 4 ?? 512} />
        </TabPanel>}
        {data.wpm && <TabPanel>
          <WPMResult data={data.wpm} />
        </TabPanel>}
        {data.w3e && <TabPanel>
          <W3EResult data={data.w3e} />
        </TabPanel>}
        {data.wct && <TabPanel>
          <WCTResult data={data.wct} />
        </TabPanel>}
        {data.j && <TabPanel>
          <JResult data={data.j} />
        </TabPanel>}
        {data.wts && <TabPanel>
          <WTSResult data={data.wts} />
        </TabPanel>}
        {data.misc && <TabPanel>
          <MiscResult data={data.misc} />
        </TabPanel>}
        {data.skin && <TabPanel>
          <SkinResult data={data.skin} />
        </TabPanel>}
        {data.extra && <TabPanel>
          <ExtraResult data={data.extra} />
        </TabPanel>}
        {data.imp && <TabPanel>
          <IMPResult data={data.imp} />
        </TabPanel>}
        {data.nameToSlk && Object.entries(data.nameToSlk).length > 0 && Object.entries(data.nameToSlk).map(slkEntry => <TabPanel>
          <SlkView data={slkEntry[1]} />
        </TabPanel>)}
      </Tabs>
  </>
}