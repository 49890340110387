import { useCallback, useRef, useState } from "react";
import { MPQDropZone } from "./MPQDropZone";
import { Mpq } from 'mpq-utils';
import { Buffer } from 'buffer';
import { readFile } from "mpq-utils";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { parseW3File } from "./FileResolveParser";
import { BLPView } from "./BLPView";
import { W3Blp } from "kaitai";

//import { MPQArchive } from 'mpyq';

export const MPQView = () => {
    const [files, setFiles] = useState<string[]>();
    const [fileName, setFileName] = useState<string>();
    const [fileContent, setFileContent] = useState<Buffer>();
    const [fileContentObj, setFileContentObj] = useState<object>();
    const [mpq, setMpq] = useState<Mpq>();

    const handleResponse = useCallback(async (response: Mpq) => {
        const mpq = response;
        setMpq(mpq);

        const listFile = readFile(mpq, '(listfile)', false);

        console.log(listFile?.toString())
        setFiles(listFile?.toString().trim().split('\r\n'))
    }, []);

    const handleClick = useCallback((filename: string) => {
        setFileName(undefined);
        setFileContent(undefined);
        setFileContentObj(undefined);
        if (mpq) {
            try {
                setFileName(filename);
                const file = readFile(mpq, filename, false);
                setFileContent(file ?? undefined);
                if (file) {
                    parseW3File(file, filename).then(response => {
                        setFileContentObj(response)
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [mpq]);

    return (
        <div className="App">
            MPQ
            <MPQDropZone onResponse={handleResponse} />
            <div style={{
                display: 'flex',
                flexFlow: 'column nowrap'
            }}>
                {mpq && <BinaryFileResultView data={mpq} />}
                <div style={{
                    display: 'flex',
                    flexFlow: 'row nowrap'
                }}>
                    {files && <div style={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        flex: '1 1 0px'
                    }}>{files.map(file => <button key={file} onClick={() => handleClick(file)}>{file}</button>)}</div>}
                    {fileName && fileContentObj && <div style={{
                        flex: '1 1 0px'
                    }}>{renderContent(fileName, fileContentObj)}</div>}
                </div>
            </div>
        </div>
    );
}

const renderContent = (filename: string, data: object) => {
    if (filename === 'war3mapMap.blp') {
        return <BLPView data={data as unknown as W3Blp} />;
    } else {
        return <BinaryFileResultView data={data} />;
    }
}