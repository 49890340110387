import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

export type TabDefinition = {
    label: string;
    render: () => JSX.Element;
}

interface TabbedViewProps {
    tabs: TabDefinition[];
}

export const TabbedView = ({tabs}: TabbedViewProps) => {
    return <Tabs>
        <TabList>
            {tabs.map(tab => <Tab key={tab.label}>{tab.label}</Tab>)}
        </TabList>
        {tabs.map(tab => <TabPanel key={tab.label}>{tab.render()}</TabPanel>)}
    </Tabs>
}