import { useState, useCallback } from "react";
import { WGCDropZone } from "./WGCDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { WGC } from "./dependency/WGC";

export const WGCView = () => {
  const [data, setData] = useState<WGC>();

  const handleResponse = useCallback((response: WGC) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      WGC
      <WGCDropZone onResponse={handleResponse} />
      {data && <WGCResult data={data} />}
    </div>
  );
}

export const WGCResult = ({data}: {data: WGC}) => {
  return <div style={{display: 'flex', flexFlow: 'column nowrap'}}>
    <label>Version</label><input type='number'></input>
    <label>Flags</label><input type='number'></input>
    <label>Base Game Speed</label><input type='number'></input>
    <label>Relative Map Path</label><input type='string'></input>
  </div>
}