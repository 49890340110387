import { useState, useCallback, MouseEventHandler, useEffect, useMemo, useRef } from "react";
import { DooUnitsDropZone } from "./DooUnitsDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { DOO_UNITS } from "./dependency/DOO_UNITS";
import { W3DooUnits, W3DooUnitsUnit } from "backend-api";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { ArrayView, resolve } from "./ResolverView";

export const DooUnitsView = () => {
  const [data, setData] = useState<DOO_UNITS>();

  const handleResponse = useCallback((response: DOO_UNITS) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      DooUnits
      <DooUnitsDropZone onResponse={handleResponse} />
      {data && <DooUnitsResult data={data} />}
    </div>
  );
}

export const DooUnitsResult = ({data: dataRaw}: {data: DOO_UNITS}) => {
  const data = dataRaw as W3DooUnits;
  return <Tabs>
    <TabList>
      <Tab>Table</Tab>
      <Tab>JSON</Tab>
    </TabList>
    <TabPanel>
      {data.numUnit}
      {data.unit && <ArrayView arr={data.unit} resolve={resolve} />}
      {/* {data.specialDoodad && <ArrayView arr={data.specialDoodad} resolve={resolve} />} */}
      {/*<BinaryFileResultView data={data} />*/}
    </TabPanel>
  </Tabs>
}

export const DOOUnitsImageResult = ({data: dataRaw, width, height, offsetX, offsetY}: {data: DOO_UNITS; width: number; height: number, offsetX: number; offsetY: number}) => {
  const data = useMemo(() => ({
    ...dataRaw as W3DooUnits,
    width,
    height,
    offsetX,
    offsetY
  }), [dataRaw]);

  const scale = 5;

  const [unitsByPoint, setUnitsByPoint] = useState<Map<number, W3DooUnitsUnit[]>>();

  const radius = 4;

  useEffect(() => {
    const newUnitsByPoint = new Map<number, W3DooUnitsUnit[]>();
    data.unit?.forEach((unit) => {
      const x = (unit.x! - offsetX) * scale / 128;
      const y = (unit.y! - offsetY) * scale / 128;
      const flippedY = data.height! * scale - y - 1;

      const effectiveX = Math.floor((x - radius / 2) / radius) * radius;
      const effectiveY = Math.floor((flippedY - radius / 2) / radius) * radius;

      const index = Math.floor(effectiveY * data.width + effectiveX);
      if (!newUnitsByPoint.has(index)) {
        newUnitsByPoint.set(index, []);
      }
      newUnitsByPoint.get(index)?.push(unit);
    });
    setUnitsByPoint(newUnitsByPoint);
  }, [data]);

  const reds = [
    255,
    0,
    28,
    84,
    255,
    254,
    32,
    229,
    149,
    126,
    16,
    74,
    155,
    0,
    0,
    190,
    235,
    248,
    191,
    220,
    40,
    235,
    0,
    164,
  ];

  const greens = [
    3,
    66,
    230,
    0,
    252,
    138,
    192,
    91,
    150,
    191,
    98,
    42,
    0,
    0,
    234,
    0,
    205,
    164,
    255,
    185,
    40,
    240,
    120,
    111
  ];

  const blues = [
    3,
    255,
    185,
    129,
    0,
    14,
    0,
    176,
    151,
    241,
    70,
    4,
    0,
    195,
    255,
    254,
    135,
    139,
    128,
    235,
    40,
    255,
    30,
    51
  ];

  const colors = reds.map((_, index) => ({
    red: reds[index],
    green: greens[index],
    blue: blues[index]
  }));

  const handleCanvasRefChange = useCallback((canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        data.unit?.forEach((unit) => {
          const x = (unit.x! - offsetX) * scale / 128;
          const y = (unit.y! - offsetY) * scale / 128;
          const color = (unit.color ? colors[unit.color === 4294967295 ? unit.ownerIndex! : unit.color] : {
            red: 0,
            green: 0,
            blue: 0
          }) ?? {
            red: 0,
            green: 0,
            blue: 0
          };
          ctx.strokeStyle = `rgba(0, 0, 0, 255)`;
          ctx.fillStyle = `rgba(${color.red}, ${color.green}, ${color.blue}, 255)`;
          ctx.fillRect(x, data.height! * scale - y - 1, 1 * scale, 1 * scale);
        });
      }
    }
  }, [data]);

  const overlayCanvasRef = useRef<HTMLCanvasElement>();

  const handleCanvasOverlayRefChange = useCallback((canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        //ctx.strokeStyle = `rgba(0, 255, 0, 255)`;
        //ctx.strokeRect(x, data.height! - y - 1, 1, 1);
      }
    }
    overlayCanvasRef.current = canvas;
  }, [data]);

  const [cursorData, setCursorData] = useState<W3DooUnitsUnit[]>();
  const [index, setIndex] = useState<number>();

  const handleMouseMove: MouseEventHandler<HTMLCanvasElement> = useCallback((event) => {
    const clientX = event.clientX;
    const clientY = event.clientY;

    const canvas = event.target as HTMLCanvasElement;

    const rect = canvas.getBoundingClientRect();

    let x = clientX - rect.left;
    let y = clientY - rect.top;

    //const width = rect.right - rect.left;

    // if (canvas.width != width) {
    //   const height = rect.bottom - rect.top;
    //   x = x * (canvas.width / width);
    //   y = y * (canvas.width / height);
    // }

    x = Math.floor(x);
    y = Math.floor(y);

    const effectiveX = Math.floor((x - radius / 2) / radius) * radius;
    const effectiveY = Math.floor((y - radius / 2) / radius) * radius;

    const index = Math.floor(effectiveY * data.width + effectiveX);

    setIndex(index);
    if (unitsByPoint) {
      setCursorData(unitsByPoint.get(index));
    } else {
      setCursorData(undefined)
    }

    const canvasOverlay = overlayCanvasRef.current;

    if (canvasOverlay) {
      const ctx = canvasOverlay.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, width * scale, height * scale);
        ctx.beginPath();
        ctx.strokeStyle = `rgba(0, 0, 0, 255)`;
        ctx.arc(x, y, radius * scale, 0, 2 * Math.PI);
        ctx.stroke();
      }
    }
  }, [data, unitsByPoint]);

  const unitsByPointSorted = useMemo(() => {
    if (unitsByPoint === undefined) {
      return undefined;
    }
    const copy = [...Array.from(unitsByPoint)];
    copy.sort();
    return copy;
  }, [unitsByPoint]);

  const handleMouseLeave = useCallback(() => {
    setCursorData(undefined);

    const canvasOverlay = overlayCanvasRef.current;

    if (canvasOverlay) {
      const ctx = canvasOverlay.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, width * scale, height * scale);
      }
    }
  }, []);

  return <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'left', gridColumn: 1, gridRow: 1}}>
      Placements
      <div style={{position: 'relative', width: width * scale, height:  width * scale, overflow: 'hidden', border: "2px solid black", boxSizing: 'border-box'}}>
        <div style={{position: 'absolute'}}>
          <canvas ref={handleCanvasRefChange} width={data.width * scale} height={data.height * scale} />
        </div>
        <div style={{position: 'absolute'}}>
          <canvas ref={handleCanvasOverlayRefChange} width={data.width * scale} height={data.height * scale} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} />
        </div>
      </div>
    </div>
    <div style={{flexGrow: 1}}>{cursorData && <BinaryFileResultView data={cursorData} />}</div>
    {/* <div>{cursorData && <ArrayView arr={cursorData} resolve={resolve} />}</div> */}
    {/* <div>{index}</div> */}
    {/* <div>{unitsByPointSorted && unitsByPointSorted.map(unit => <div key={unit[0]}>{unit[0]}</div>)}</div> */}
  </div>
}