import { useState, useCallback, useMemo } from "react";
import { WTGDropZone } from "./WTGDropZone";
import { WTG } from "./dependency/WTG";
import { RootContext } from './dependency/txtParser';
import { TXTDropZone } from "./TXTDropZone";
import TreeView, { ITreeViewOnNodeSelectProps, flattenTree } from "react-accessible-treeview";
import { W3Wtg131, WtgECA, WtgParam, WtgParamType } from "kaitai";
import './WTGView.css';

export const WTGView = () => {
  const [triggerData, setTriggerData] = useState<RootContext>();
  const [data, setData] = useState<WTG>();

  const handleTriggerDataResponse = useCallback((response: RootContext) => {
    setTriggerData(response);
  }, []);

  const handleResponse = useCallback((response: WTG) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      WTG
      <TXTDropZone onResponse={handleTriggerDataResponse} />
      {triggerData && <WTGDropZone triggerData={triggerData} onResponse={handleResponse} />}
      {data && <WTGResult data={data} />}
    </div>
  );
}

export const WTGResult = ({data}: {data: WTG}) => {
  const treeData = useMemo(() => {
    return {
      name: '',
      children: data.element.filter(element => element.trig).map(element => {
        return {
          id: element.trig?.id,
          name: element.trig?.name.value!,
        }
      })
    }
  }, []);
  const flattenedTreeData = useMemo(() => flattenTree(treeData), [treeData]);

  const [selectedTrig, setSelectedTrig] = useState<W3Wtg131['element'][0]>();

  const handleNodeSelect = useCallback((props: ITreeViewOnNodeSelectProps) => {
    const id = props.element.id;
    setSelectedTrig(data.element.find(element => element.trig?.id === id));
  }, []);

  return <div>
    {/* Vars
    <div>
      {data.element.filter(element => element.var).map(element => <p>{element.var?.name.value}</p>)}
    </div>

    Triggers

    <div>
      {data.element.filter(element => element.trig).map(element => <p>{element.trig?.name.value}</p>)}
    </div> */}

    <TreeView
      data={flattenedTreeData}
      className="basic"
      aria-label="basic example tree"
      onNodeSelect={handleNodeSelect}
      nodeRenderer={({ element, getNodeProps, level, handleSelect }) => (
        <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
          {element.name}
        </div>
      )}
    />

    {selectedTrig && <TrigView element={selectedTrig} />}
  </div>;
  //return <BinaryFileResultView data={data} />
}

interface TrigViewProps {
  element: W3Wtg131['element'][0];
}

const TrigView = ({element}: TrigViewProps) => {
  const calcParamName = (param: WtgParam): string => {
    if (param.hasSub) {
      return `${param.value.value}(${param.subParam?.param.map(child => calcParamName(child)).join(', ')})`;
    } else {
      if (param.paramType !== undefined) {
        switch (param.paramType) {
          case WtgParamType.FUNCTION: {
            return `${param.value.value}()`
          }
          default: {
            return param.value.value;
          }
        }
      }
      return param.value.value;
    }
  };

  const calcECAName = (eca: WtgECA) => {
    return `${eca.func.realName.value}(${eca.param.map(param => calcParamName(param)).join(', ')})`;
  };

  const convertECA = (eca: WtgECA): any => {
    return {
      name: calcECAName(eca),
      children: eca.childEca?.map(child => convertECA(child)) ?? []
    }
  };
  const eventTreeData = useMemo(() => {
    return {
      name: 'Events',
      children: [{
        name: 'Events',
        children: element.trig?.eca.filter(eca => eca.type === 0).map(eca => {
          return convertECA(eca)
        })
      }]
    }
  }, [element]);
  const conditionTreeData = useMemo(() => {
    return {
      name: 'Conditions',
      children: [{
        name: 'Conditions',
        children: element.trig?.eca.filter(eca => eca.type === 1).map(eca => {
          return convertECA(eca)
        })
      }]
    }
  }, [element]);
  const actionTreeData = useMemo(() => {
    return {
      name: 'Actions',
      children: [{
        name: 'Actions',
        children: element.trig?.eca.filter(eca => eca.type === 2).map(eca => {
          return convertECA(eca)
        })
      }]
    }
  }, [element]);

  const eventFlattenedTreeData = useMemo(() => flattenTree(eventTreeData), [eventTreeData]);
  const conditionFlattenedTreeData = useMemo(() => flattenTree(conditionTreeData), [conditionTreeData]);
  const actionFlattenedTreeData = useMemo(() => flattenTree(actionTreeData), [actionTreeData]);

  return <div className="directory" style={{
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'start'
  }}>
    <TreeView
      data={eventFlattenedTreeData}
      aria-label="basic example tree"
      nodeRenderer={({ element, getNodeProps, level, handleSelect }) => (
        <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
          {element.name}
        </div>
      )}
    />
    <TreeView
      data={conditionFlattenedTreeData}
      aria-label="basic example tree"
      nodeRenderer={({ element, getNodeProps, level, handleSelect }) => (
        <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
          {element.name}
        </div>
      )}
    />
    <TreeView
      data={actionFlattenedTreeData}
      aria-label="basic example tree"
      nodeRenderer={({ element, getNodeProps, level, handleSelect }) => (
        <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
          {element.name}
        </div>
      )}
    />
  </div>
}