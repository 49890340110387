export enum W3FileType {
    DOO,
    DOO_UNITS,
    W3U,
    W3T,
    W3B,
    W3D,
    W3A,
    W3H,
    W3Q,
    MMP,
    MPQ,
    W3C,
    W3R,
    W3S,
    W3I,
    W3E,
    WAI,
    W3V,
    WPM,
    SHD,
    WCT,
    WTG,
    WTS,
    BLP,
    TGA,
    J,
    TXT,
    JSON,
    W3F,
    WGC
}

export const W3FileTypeFromFileName = (fileName: string): W3FileType => {
    switch (fileName) {
        case 'war3map.doo': return W3FileType.DOO;
        case 'war3mapUnit.doo': return W3FileType.DOO_UNITS;
        case 'war3map.w3a': return W3FileType.W3A;
        case 'war3mapSkin.w3a': return W3FileType.W3A;
        case 'war3map.w3b': return W3FileType.W3A;
        case 'war3mapSkin.w3b': return W3FileType.W3A;
        case 'war3map.w3c': return W3FileType.W3C;
        case 'war3map.w3d': return W3FileType.W3U;
        case 'war3mapSkin.w3d': return W3FileType.W3U;
        case 'war3map.w3h': return W3FileType.W3U;
        case 'war3mapSkin.w3h': return W3FileType.W3U;
        case 'war3map.w3r': return W3FileType.W3R;
        case 'war3map.w3t': return W3FileType.W3U;
        case 'war3mapSkin.w3t': return W3FileType.W3U;
        case 'war3map.w3u': return W3FileType.W3U;
        case 'war3mapSkin.w3u': return W3FileType.W3U;
        case 'war3map.w3s': return W3FileType.W3S;
        case 'war3map.w3i': return W3FileType.W3I;
        case 'war3map.w3e': return W3FileType.W3E;
        case 'war3map.mmp': return W3FileType.MMP;
        case 'war3map.shd': return W3FileType.SHD;
        case 'war3map.wpm': return W3FileType.WPM;
        case 'war3map.wct': return W3FileType.WCT;
        case 'war3map.wpm': return W3FileType.WPM;
        case 'war3map.wtg': return W3FileType.WTG;
        case 'war3map.j': return W3FileType.J;
        case 'war3map.wts': return W3FileType.WTS;
        case 'war3mapMap.blp': return W3FileType.BLP;
        case 'war3mapMisc.txt': return W3FileType.TXT;
        case 'war3map.wpm': return W3FileType.WPM;
        case 'conversation.json': return W3FileType.JSON;
    }
    throw new Error('no W3FileType for ' + fileName);
}