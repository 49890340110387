// Generated from txt.g4 by ANTLR 4.13.0
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import {
	ATN,
	ATNDeserializer,
	CharStream,
	DecisionState, DFA,
	Lexer,
	LexerATNSimulator,
	RuleContext,
	PredictionContextCache,
	Token
} from "antlr4";
export default class txtLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly COMMENT = 4;
	public static readonly NL = 5;
	public static readonly STR = 6;
	public static readonly COMMA = 7;
	public static readonly WS = 8;
	public static readonly EOF = Token.EOF;

	public static readonly channelNames: string[] = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	public static readonly literalNames: (string | null)[] = [ null, "'['", 
                                                            "']'", "'='", 
                                                            null, null, 
                                                            null, "','" ];
	public static readonly symbolicNames: (string | null)[] = [ null, null, 
                                                             null, null, 
                                                             "COMMENT", 
                                                             "NL", "STR", 
                                                             "COMMA", "WS" ];
	public static readonly modeNames: string[] = [ "DEFAULT_MODE", ];

	public static readonly ruleNames: string[] = [
		"T__0", "T__1", "T__2", "COMMENT", "NL", "STR", "COMMA", "WS",
	];


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(this, txtLexer._ATN, txtLexer.DecisionsToDFA, new PredictionContextCache());
	}

	public get grammarFileName(): string { return "txt.g4"; }

	public get literalNames(): (string | null)[] { return txtLexer.literalNames; }
	public get symbolicNames(): (string | null)[] { return txtLexer.symbolicNames; }
	public get ruleNames(): string[] { return txtLexer.ruleNames; }

	public get serializedATN(): number[] { return txtLexer._serializedATN; }

	public get channelNames(): string[] { return txtLexer.channelNames; }

	public get modeNames(): string[] { return txtLexer.modeNames; }

	public static readonly _serializedATN: number[] = [4,0,8,78,6,-1,2,0,7,
	0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,1,0,1,0,1,1,1,
	1,1,2,1,2,1,3,1,3,1,3,1,3,5,3,28,8,3,10,3,12,3,31,9,3,1,4,4,4,34,8,4,11,
	4,12,4,35,1,5,4,5,39,8,5,11,5,12,5,40,1,5,1,5,4,5,45,8,5,11,5,12,5,46,1,
	5,1,5,4,5,51,8,5,11,5,12,5,52,1,5,1,5,4,5,57,8,5,11,5,12,5,58,1,5,3,5,62,
	8,5,3,5,64,8,5,3,5,66,8,5,1,6,1,6,1,6,1,6,1,7,4,7,73,8,7,11,7,12,7,74,1,
	7,1,7,0,0,8,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,8,1,0,4,2,0,10,10,13,13,7,
	0,10,10,13,13,34,34,44,44,61,61,91,91,93,93,3,0,10,10,13,13,34,34,2,0,9,
	9,32,32,88,0,1,1,0,0,0,0,3,1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,
	0,11,1,0,0,0,0,13,1,0,0,0,0,15,1,0,0,0,1,17,1,0,0,0,3,19,1,0,0,0,5,21,1,
	0,0,0,7,23,1,0,0,0,9,33,1,0,0,0,11,65,1,0,0,0,13,67,1,0,0,0,15,72,1,0,0,
	0,17,18,5,91,0,0,18,2,1,0,0,0,19,20,5,93,0,0,20,4,1,0,0,0,21,22,5,61,0,
	0,22,6,1,0,0,0,23,24,5,47,0,0,24,25,5,47,0,0,25,29,1,0,0,0,26,28,8,0,0,
	0,27,26,1,0,0,0,28,31,1,0,0,0,29,27,1,0,0,0,29,30,1,0,0,0,30,8,1,0,0,0,
	31,29,1,0,0,0,32,34,7,0,0,0,33,32,1,0,0,0,34,35,1,0,0,0,35,33,1,0,0,0,35,
	36,1,0,0,0,36,10,1,0,0,0,37,39,8,1,0,0,38,37,1,0,0,0,39,40,1,0,0,0,40,38,
	1,0,0,0,40,41,1,0,0,0,41,42,1,0,0,0,42,44,5,91,0,0,43,45,8,1,0,0,44,43,
	1,0,0,0,45,46,1,0,0,0,46,44,1,0,0,0,46,47,1,0,0,0,47,48,1,0,0,0,48,66,5,
	93,0,0,49,51,8,1,0,0,50,49,1,0,0,0,51,52,1,0,0,0,52,50,1,0,0,0,52,53,1,
	0,0,0,53,66,1,0,0,0,54,63,5,34,0,0,55,57,8,2,0,0,56,55,1,0,0,0,57,58,1,
	0,0,0,58,56,1,0,0,0,58,59,1,0,0,0,59,61,1,0,0,0,60,62,5,34,0,0,61,60,1,
	0,0,0,61,62,1,0,0,0,62,64,1,0,0,0,63,56,1,0,0,0,63,64,1,0,0,0,64,66,1,0,
	0,0,65,38,1,0,0,0,65,50,1,0,0,0,65,54,1,0,0,0,66,12,1,0,0,0,67,68,5,44,
	0,0,68,69,1,0,0,0,69,70,6,6,0,0,70,14,1,0,0,0,71,73,7,3,0,0,72,71,1,0,0,
	0,73,74,1,0,0,0,74,72,1,0,0,0,74,75,1,0,0,0,75,76,1,0,0,0,76,77,6,7,0,0,
	77,16,1,0,0,0,11,0,29,35,40,46,52,58,61,63,65,74,1,6,0,0];

	private static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!txtLexer.__ATN) {
			txtLexer.__ATN = new ATNDeserializer().deserialize(txtLexer._serializedATN);
		}

		return txtLexer.__ATN;
	}


	static DecisionsToDFA = txtLexer._ATN.decisionToState.map( (ds: DecisionState, index: number) => new DFA(ds, index) );
}