import { useState, useCallback, ReactEventHandler } from "react";
import { WPMDropZone } from "./WPMDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { WPM } from "./dependency/WPM";
import { W3Wpm } from "backend-api";

export const WPMView = () => {
  const [data, setData] = useState<WPM>();

  const handleResponse = useCallback((response: WPM) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      WPM
      <WPMDropZone onResponse={handleResponse} />
      {data && <WPMResult data={data} />}
    </div>
  );
}

export const WPMResult = ({data: dataRaw}: {data: WPM}) => {
  const data = dataRaw as W3Wpm;

  const handleCanvas1RefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.pathingNode?.forEach((pathingNode, index) => {
          const x = index % data.width!;
          const y = index / data.width!;
          if (pathingNode) {
            const red = ((pathingNode & 0x2) >> 1) * 255;
            const green = ((pathingNode & 0x4) >> 2) * 255;
            const blue = ((pathingNode & 0x8) >> 3) * 255;
            ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
            ctx.strokeRect(x, data.height! - y - 1, 1, 1);
          }
        });
      }
    }
  };

  const handleCanvas2RefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.pathingNode?.forEach((pathingNode, index) => {
          const x = index % data.width!;
          const y = index / data.width!;
          if ((pathingNode & 0x40) >> 6) {
            const red = 255;
            const green = 192;
            const blue = 203;
            ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
            ctx.strokeRect(x, data.height! - y - 1, 1, 1);
          }
        });
      }
    }
  };

  const handleCanvas3RefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.pathingNode?.forEach((pathingNode, index) => {
          const x = index % data.width!;
          const y = index / data.width!;
          if (pathingNode) {
            const red = ((pathingNode & 0x20) >> 5) * 255;
            const green = 255 - ((pathingNode & 0x40) >> 6) * 255;
            const blue = ((pathingNode & 0x80) >> 7) * 255;
            ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
            ctx.strokeRect(x, data.height! - y - 1, 1, 1);
          }
        });
      }
    }
  };

  return <div style={{display: 'flex'}}>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'left', width: data.width, height: data.height}}>
      Pathing Texture
      <canvas ref={handleCanvas1RefChange} width={data.width} height={data.height} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'left', width: data.width, height: data.height}}>
      Water
      <canvas ref={handleCanvas2RefChange} width={data.width} height={data.height} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'left', width: data.width, height: data.height}}>
      Blight & Water
      <canvas ref={handleCanvas3RefChange} width={data.width} height={data.height} style={{border: "1px solid black"}} />
    </div>
  </div>
}