import { useState, useCallback } from "react";
import { W3VDropZone } from "./W3VDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { W3V } from "./dependency/W3V";

export const W3VView = () => {
  const [data, setData] = useState<W3V>();

  const handleResponse = useCallback((response: W3V) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3V
      <W3VDropZone onResponse={handleResponse} />
      {data && <W3VResult data={data} />}
    </div>
  );
}

export const W3VResult = ({data}: {data: W3V}) => {
  return <BinaryFileResultView data={data} />
}