import { W3Blp } from "kaitai";
import { MutableRefObject, Ref, useEffect, useMemo, useRef, useState } from "react";

type BLPViewProps = {
    data: W3Blp;
}

const bytesToBase64 = (bytes: number[]) => {
    let binary = '';
    const len = bytes.length;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return globalThis.btoa(binary);
}

const reverseColors = (bytes: number[]) => {
    const result: number[] = [];
    const len = bytes.length;
    for (let i = 0; i < len; i++) {
        result[i] = bytes[i];
    }
    for (let i = 0; i < len; i++) {
        const mod = i % 4;
        const chunk = Math.floor(i / 4);
        switch (mod) {
            case 0: {
                result[chunk * 4 + 2] = bytes[i];
                break;
            }
            case 1: {
                result[chunk * 4 + 1] = bytes[i];
                break;
            }
            case 2: {
                result[chunk * 4 + 0] = bytes[i];
                break;
            }
            case 3: {
                result[chunk * 4 + 3] = bytes[i];
                break;
            }
        }
    }
    return result;
}

export const BLPView = ({data}: BLPViewProps) => {
    const base64 = useMemo(() => {
        return bytesToBase64(data.jpgHeader.headerChunk.concat(data.mipmap0));
    }, [data]);
    const dataUrl = useMemo(() => {
        return 'data:image/jpg;base64,' + base64;
    }, [base64]);

    useEffect(() => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = data.header.width;
        canvas.height = data.header.height;
        const img = document.createElement('img');
        img.onload = () => {
            if (ctx) {
                ctx.drawImage(img, 0, 0);
                const newImageData = ctx.getImageData(0, 0, img.width, img.height);
                const fixedColors = reverseColors(Array.from(newImageData.data));
                for (let i = 0; i < fixedColors.length; i++) {
                    newImageData.data[i] = fixedColors[i];
                }
                setImageData(newImageData);
            }
        };
        img.src = dataUrl;
    }, [dataUrl]);

    const [imgData, setImageData] = useState<ImageData>();
    
    const canvasRef: MutableRefObject<HTMLCanvasElement | null> = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            if (ctx && imgData) {
                ctx.putImageData(imgData, 0, 0);
            }
        }
    }, []);
    
    const onRefChange = (canvas: HTMLCanvasElement) => {
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx && imgData) {
                //const imgData = ctx.createImageData(data.header.width, data.header.height);
                //const pixelData = data.mipmap0 as unknown as number[];
                //for (let i = 0; i < pixelData.length; i++) {
                //    imgData.data[i] = pixelData[i];
                //}
                //ctx?.putImageData(imgData, 0, 0);
                ctx.putImageData(imgData, 0, 0);
            }
        }
        canvasRef.current = canvas;
    };
    return <>
        <canvas ref={onRefChange} width={data.header.width} height={data.header.height} style={{
            border: '1px solid black'
        }} />
    </>;
}