import { Tga } from "kaitai";
import { MutableRefObject, Ref, useEffect, useMemo, useRef, useState } from "react";

type TGAViewProps = {
    data: Tga;
}

export const TGAView = ({data}: TGAViewProps) => {
    const onRefChange = (canvas: HTMLCanvasElement) => {
        const ctx = canvas.getContext('2d');
        if (ctx) {
            data._io.seek(data._io.pos + 44);
        }
    };
    return <>
        <canvas ref={onRefChange} width={data.width} height={data.height} style={{
            border: '1px solid black'
        }} />
    </>;
}