import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { parseDooUnits } from "./FileResolveParser";
import { DOO_UNITS } from "./dependency/DOO_UNITS";

type DooUnitsDropZoneProps = {
  onResponse: (val: DOO_UNITS) => void;
}

export const DooUnitsDropZone = ({onResponse}: DooUnitsDropZoneProps) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    parseDooUnits(acceptedFiles[0])
      .then(response => onResponse(response));
  }, [onResponse])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} style={{
      border: '1px dashed grey'
    }}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}