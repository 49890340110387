import { useState, useCallback, useEffect, useRef } from "react";
import { W3EDropZone } from "./W3EDropZone";
import { W3E } from "./dependency/W3E";
import { W3W3e } from "backend-api";

export const W3EView = () => {
  const [data, setData] = useState<W3E>();

  const handleResponse = useCallback((response: W3E) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3E
      <W3EDropZone onResponse={handleResponse} />
      {data && <W3EResult data={data} />}
    </div>
  );
}

export const W3EResult = ({data: dataRaw}: {data: W3E}) => {
  const data = dataRaw as W3W3e;

  const handleCanvasTexRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        const tileIdColors = data.tileId?.map(() => ({
          red: Math.random() * 255,
          green: Math.random() * 255,
          blue: Math.random() * 255,
          alpha: 255,
        }));
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode && tileIdColors) {
            const {red, green, blue} = tileIdColors[tileNode.flagsAndGroundTexture!.texture! & 0xF] ?? {
              red: 0,
              green: 0,
              blue: 0
            };
            ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
            ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
          }
        });
      }
    }
  };

  const handleCanvasCliffRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        const cliffTileIdColors = data.cliffTileId?.map(() => ({
          red: Math.random() * 255,
          green: Math.random() * 255,
          blue: Math.random() * 255,
          alpha: 255,
        }));
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode && cliffTileIdColors) {
            const {red, green, blue} = cliffTileIdColors[tileNode.cliffTextureAndLayerHeight?.texture! & 0xF] ?? {
              red: 0,
              green: 0,
              blue: 0
            };
            ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
            ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
          }
        });
      }
    }
  };

  const handleCanvasLayerRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode) {
            const colorValue = 255 * tileNode.cliffTextureAndLayerHeight!.layer! / 15;
            const red = colorValue;
            const green = colorValue;
            const blue = colorValue;
            ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
            ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
          }
        });
      }
    }
  };

  const handleCanvasWaterRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode) {
            if (tileNode.flagsAndGroundTexture!.water) {
              ctx.strokeStyle = `rgba(0, 0, 255, 255)`;
              ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
            }
          }
        });
      }
    }
  };

  const handleCanvasBlightRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode) {
            if (tileNode.flagsAndGroundTexture!.blight) {
              ctx.strokeStyle = `rgba(0, 0, 255, 255)`;
              ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
            }
          }
        });
      }
    }
  };

  const handleCanvasRampRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode) {
            if (tileNode.flagsAndGroundTexture!.ramp) {
              ctx.strokeStyle = `rgba(0, 0, 255, 255)`;
              ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
            }
          }
        });
      }
    }
  };

  const handleCanvasBoundary1RefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode) {
            if (tileNode.waterHeightAndBoundary?.flag) {
              ctx.strokeStyle = `rgba(0, 0, 255, 255)`;
              ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
            }
          }
        });
      }
    }
  };

  const handleCanvasBoundary2RefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode) {
            if (tileNode.flagsAndGroundTexture!.boundary2) {
              ctx.strokeStyle = `rgba(0, 0, 255, 255)`;
              ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
            }
          }
        });
      }
    }
  };

  const groundHeightCanvasRef = useRef<HTMLCanvasElement>();

  const handleCanvasGroundHeightRefChange = (canvas: HTMLCanvasElement) => {
    groundHeightCanvasRef.current = canvas;
  };

  const [applyNormalizeGroundHeight, setApplyNormalizeGroundHeight] = useState<boolean>(false);

  useEffect(() => {
    const canvas = groundHeightCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        let minFinalHeight = encodedGroundHeightAndLayerToFinalHeight(0x1E00, 0);
        let maxFinalHeight = encodedGroundHeightAndLayerToFinalHeight(0x3800, 14);
        if (applyNormalizeGroundHeight) {
          const finalHeights = data.tileNode?.map(tileNode => encodedGroundHeightAndLayerToFinalHeight(tileNode.groundHeight!, tileNode.cliffTextureAndLayerHeight?.layer!));
          if (finalHeights) {
            minFinalHeight = Math.min(...finalHeights);
            maxFinalHeight = Math.max(...finalHeights);
          }
        }
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode) {
            const finalHeight = encodedGroundHeightAndLayerToFinalHeight(tileNode.groundHeight!, tileNode.cliffTextureAndLayerHeight?.layer!);
            const colorValue = 255 * ((finalHeight - minFinalHeight) / (maxFinalHeight - minFinalHeight));
            //const {red, green, blue} = HSVtoRGB(colorValue * 360 / 255, 1, 1);
            //const red = colorValue;
            //const green = colorValue;
            //const blue = colorValue;
            const red = colorValue;
            const green = 255 - colorValue;
            const blue = 0;
            ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
            ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
          }
        });
      }
    }
  }, [data, applyNormalizeGroundHeight, groundHeightCanvasRef.current]);

  const handleCanvasWaterHeightRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.tileNode?.forEach((tileNode, index) => {
          const x = index % data.mapWidth!;
          const y = index / data.mapHeight!;
          if (tileNode) {
            const finalHeight = (tileNode.waterHeightAndBoundary?.waterHeight! - 0x2000) / 4 - 89.6;
            const colorValue = 255 * (finalHeight + 2500) / 500;
            const red = colorValue;
            const green = colorValue;
            const blue = colorValue;
            ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
            ctx.strokeRect(x, data.mapHeight! - y - 1, 1, 1);
          }
        });
      }
    }
  };

  return <div style={{display: 'grid'}}>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 1, gridRow: 1}}>
      Texture
      <canvas ref={handleCanvasTexRefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 2, gridRow: 1}}>
      Cliff Texture
      <canvas ref={handleCanvasCliffRefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 1, gridRow: 2}}>
      Layer
      <canvas ref={handleCanvasLayerRefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 2, gridRow: 2}}>
      Water
      <canvas ref={handleCanvasWaterRefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 1, gridRow: 3}}>
      Blight
      <canvas ref={handleCanvasBlightRefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 2, gridRow: 3}}>
      Ramp
      <canvas ref={handleCanvasRampRefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 1, gridRow: 4}}>
      Boundary1
      <canvas ref={handleCanvasBoundary1RefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 2, gridRow: 4}}>
      Boundary2
      <canvas ref={handleCanvasBoundary2RefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <label htmlFor="applyContext">Normalize Ground Height</label>
    <input id="applyContext" type="checkbox" checked={applyNormalizeGroundHeight} onChange={(event) => setApplyNormalizeGroundHeight(event.target.checked)} />
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 1, gridRow: 5}}>
      Ground Height
      <canvas ref={handleCanvasGroundHeightRefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 2, gridRow: 5}}>
      Water Height
      <canvas ref={handleCanvasWaterHeightRefChange} width={data.mapWidth} height={data.mapHeight} style={{border: "1px solid black"}} />
    </div>
  </div>
}

const encodedGroundHeightAndLayerToFinalHeight = (encodedGroundHeight: number, encodedLayer: number) => {
  return (encodedGroundHeight - 0x2000 + (encodedLayer - 2) * 0x0200) / 4;
}

/* function mix(a: number, b: number, v: number)
{
    return (1-v)*a + v*b;
}

function HSVtoRGB(H: number, S: number, V: number)
{
    var V2 = V * (1 - S);
    var r  = ((H>=0 && H<=60) || (H>=300 && H<=360)) ? V : ((H>=120 && H<=240) ? V2 : ((H>=60 && H<=120) ? mix(V,V2,(H-60)/60) : ((H>=240 && H<=300) ? mix(V2,V,(H-240)/60) : 0)));
    var g  = (H>=60 && H<=180) ? V : ((H>=240 && H<=360) ? V2 : ((H>=0 && H<=60) ? mix(V2,V,H/60) : ((H>=180 && H<=240) ? mix(V,V2,(H-180)/60) : 0)));
    var b  = (H>=0 && H<=120) ? V2 : ((H>=180 && H<=300) ? V : ((H>=120 && H<=180) ? mix(V2,V,(H-120)/60) : ((H>=300 && H<=360) ? mix(V,V2,(H-300)/60) : 0)));

    return {
        red: Math.round(r * 255),
        green: Math.round(g * 255),
        blue: Math.round(b * 255)
    };
} */