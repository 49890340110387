import { useState, useCallback } from "react";
import { W3QDropZone } from "./W3QDropZone";
import { W3Q } from "./dependency/W3Q";
import { W3W3q } from "backend-api";
import { ObjModTabbedView } from "./ObjModTabbedView";
import { Context } from "./Context";

export const W3QView = () => {
  const [data, setData] = useState<W3Q>();

  const handleResponse = useCallback((response: W3Q) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3Q
      <W3QDropZone onResponse={handleResponse} />
      {data && <W3QResult data={data} context={undefined} />}
    </div>
  );
}

export const W3QResult = ({data: dataRaw, context}: {data: W3Q, context?: Context}) => {
  const data = dataRaw as W3W3q;
  return <ObjModTabbedView data={data} context={context} />
}