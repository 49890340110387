import { useState, useCallback } from "react";
import { W3RDropZone } from "./W3RDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { W3R } from "./dependency/W3R";
import { W3W3r } from "backend-api";

export const W3RView = () => {
  const [data, setData] = useState<W3R>();

  const handleResponse = useCallback((response: W3R) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3R
      <W3RDropZone onResponse={handleResponse} />
      {data && <W3RResult data={data} />}
    </div>
  );
}

export const W3RResult = ({data}: {data: W3R}) => {
  return <BinaryFileResultView data={data} />
}

export const W3RVisualResult = ({data: dataRaw, width, height, offsetX, offsetY}: {data: W3R; width: number; height: number; offsetX: number; offsetY: number}) => {
  const data = {
    ...dataRaw as W3W3r,
    width,
    height,
    offsetX,
    offsetY
  };

  const scale = 3;

  const handleCanvasRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.region?.forEach((region) => {
          const min_x = (region.left! - offsetX) * scale / 128;
          const max_x = (region.right! - offsetX) * scale / 128;
          const min_y = (region.bottom! - offsetY) * scale / 128;
          const max_y = (region.top! - offsetY) * scale / 128;
          const {red, green, blue} = {
            red: region.colorRed,
            green: region.colorGreen,
            blue: region.colorBlue
          };
          ctx.strokeStyle = `rgba(0, 0, 0, 255)`;
          ctx.fillStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
          ctx.fillRect(min_x, data.height * scale - min_y, max_x - min_x, max_y - min_y);
        });
      }
    }
  };

  return <div style={{display: 'grid'}}>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center', gridColumn: 1, gridRow: 1}}>
      Regions
      <canvas ref={handleCanvasRefChange} width={data.width * scale} height={data.height * scale} style={{border: "1px solid black"}} />
    </div>
  </div>
}