import { useState, useCallback } from "react";
import { W3HDropZone } from "./W3HDropZone";
import { W3H } from "./dependency/W3H";
import { W3W3h } from "backend-api";
import { ObjModTabbedView } from "./ObjModTabbedView";
import { Context } from "./Context";

export const W3HView = () => {
  const [data, setData] = useState<W3H>();

  const handleResponse = useCallback((response: W3H) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3H
      <W3HDropZone onResponse={handleResponse} />
      {data && <W3HResult data={data} />}
    </div>
  );
}

export const W3HResult = ({data: dataRaw, context}: {data: W3H, context?: Context}) => {
  const data = dataRaw as W3W3h;
  return <ObjModTabbedView data={data} context={context} />
}