import { useState, useCallback } from "react";
import { BLPView } from "./BLPView";
import { BLPDropZone } from "./BLPDropZone";

export const BLPPanel = () => {
  const [data, setData] = useState<any>();

  const handleResponse = useCallback((response: any) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      BLP
      <BLPDropZone onResponse={handleResponse} />
      {data && <BLPView data={data} />}
    </div>
  );
}