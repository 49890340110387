const bytesToString = function(data)
  {
    const extraByteMap = [ 1, 1, 1, 1, 2, 2, 3, 0 ];
    var count = data.length;
    var str = "";
    
    for (var index = 0;index < count;)
    {
      var ch = data[index++];
      if (ch & 0x80)
      {
        var extra = extraByteMap[(ch >> 3) & 0x07];
        if (!(ch & 0x40) || !extra || ((index + extra) > count))
          return null;
        
        ch = ch & (0x3F >> extra);
        for (;extra > 0;extra -= 1)
        {
          var chx = data[index++];
          if ((chx & 0xC0) != 0x80)
            return null;
          
          ch = (ch << 6) | (chx & 0x3F);
        }
      }
      
      str += String.fromCharCode(ch);
    }
    
    return str;
  }

class FuncLookup {
    static dict = null;

    constructor() {
    };

    decode(bytes) {
        const name = bytesToString(bytes);
        const num_param = FuncLookup.dict.get(name);
        if (num_param === undefined) {
          throw new Error('unknown function ' + name);
        }
        const num_param_bytes = new Uint32Array(4);
        num_param_bytes[0] = num_param;
        return new Uint8Array([...num_param_bytes, ...bytes, 0]);
    };
}

module.exports = FuncLookup;