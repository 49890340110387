import { useState, useCallback } from "react";
import { W3BDropZone } from "./W3BDropZone";
import { W3B } from "./dependency/W3B";
import { ObjModTabbedView } from "./ObjModTabbedView";
import { W3W3b } from "backend-api";
import { Context } from "./Context";

export const W3BView = () => {
  const [data, setData] = useState<W3B>();

  const handleResponse = useCallback((response: W3B) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3B
      <W3BDropZone onResponse={handleResponse} />
      {data && <W3BResult data={data} />}
    </div>
  );
}

export const W3BResult = ({data: dataRaw, context}: {data: W3B, context?: Context}) => {
  const data = dataRaw as W3W3b;
  return <ObjModTabbedView data={data} context={context} />
}