import { useState, useCallback } from "react";
import { W3FDropZone } from "./W3FDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { W3F } from "./dependency/W3F";
import { Context } from './Context';

export const W3FView = () => {
  const [data, setData] = useState<W3F>();

  const handleResponse = useCallback((response: W3F) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3F
      <W3FDropZone onResponse={handleResponse} />
      {data && <W3FResult data={data} />}
    </div>
  );
}

export const W3FResult = ({data, context}: {data: W3F, context?: Context}) => {
  return <BinaryFileResultView data={data} context={context} />
}