import { useState, useCallback } from "react";
import { MMPDropZone } from "./MMPDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { MMP } from "./dependency/MMP";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { W3Mmp } from "backend-api";
import { ArrayView, resolve } from "./ResolverView";

export const MMPView = () => {
  const [data, setData] = useState<MMP>();

  const handleResponse = useCallback((response: MMP) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      MMP
      <MMPDropZone onResponse={handleResponse} />
      {data && <MMPResult data={data} />}
    </div>
  );
}

export const MMPResult = ({data}: {data: MMP}) => {
  return <Tabs>
    <TabList>
      <Tab>JSON</Tab>
      <Tab></Tab>
    </TabList>
    <TabPanel><BinaryFileResultView data={data} /></TabPanel>
    <TabPanel><MMPVisualResult data={data} width={512} height={512} offsetX={0} offsetY={0} /></TabPanel>
  </Tabs>
}

export const MMPVisualResult = ({data: dataRaw, width, height, offsetX, offsetY}: {data: MMP; width: number; height: number, offsetX: number; offsetY: number}) => {
  const data = {
    ...dataRaw as W3Mmp,
    width,
    height,
    offsetX,
    offsetY
  };

  const handleCanvasRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = `rgba(0, 0, 0, 255)`;
        ctx.fillRect(0, 0, data.width, data.height);
        data.iconsChunk?.icon?.forEach((icon) => {
          const x = icon.x!;
          const y = icon.y!;
          const red = icon.color?.red
          const green = icon.color?.green;
          const blue = icon.color?.blue;
          const alpha = icon.color?.alpha;
          ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
          ctx.fillStyle = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
          ctx.fillRect(x, y, 5, 5);
        });
      }
    }
  };

  return <>
    <canvas ref={handleCanvasRefChange} width={data.width} height={data.height} style={{border: "1px solid black"}} />
    <div><ArrayView arr={data.iconsChunk?.icon!} resolve={resolve} /></div>
  </>
}