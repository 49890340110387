export const SpecsPanel = () => {
    return <>
        <iframe src="https://world-editor-tutorials.thehelper.net/cat_usersubmit.php?view=42787" />
        <iframe src="https://xgm.guru/p/wc3/w3-file-format" />
        <iframe src="https://github.com/stijnherfst/HiveWE/wiki" />
        <iframe src="https://www.hiveworkshop.com/threads/blp-specifications-wc3.279306/" />
        <iframe src="https://867380699.github.io/blog/2019/05/09/W3X_Files_Format" />
        <iframe src="https://searchcode.com/file/167900743/contrib/vmap_extractor_v2/doc/The+MoPaQ+File+Format.txt/" />
        <iframe src="http://www.zezula.net/en/mpq/mpqformat.html" />
        <iframe src="https://github.com/Kruithne/js-blp" />
    </>
}