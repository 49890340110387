import { useState, useCallback } from "react";
import { W3CDropZone } from "./W3CDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { W3C } from "./dependency/W3C";
import { W3W3c } from "backend-api";
import Spreadsheet from "react-spreadsheet";

export const W3CView = () => {
  const [data, setData] = useState<W3C>();

  const handleResponse = useCallback((response: W3C) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3C
      <W3CDropZone onResponse={handleResponse} />
      {data && <W3CResult data={data} />}
    </div>
  );
}

export const W3CResult = ({data: dataRaw}: {data: W3C}) => {
  const data = dataRaw as W3W3c;
  const firstCamera = data.camera?.[0];
  const fieldSheetData = [Object.entries(firstCamera ?? {}).map(entry => ({value: `${entry[0]}`} ?? '')) ?? []];
  const objSheetData = data.camera?.map(camera => Object.entries(camera).map(entry => ({value: resolve(entry[1])} ?? '')) ?? []) ?? [];
  const sheetData = fieldSheetData.concat(objSheetData);
  return <Spreadsheet data={sheetData} />
}

const resolve = (data: any) => {
  if (data !== null && typeof data  === 'object') {
    if (typeof data.value === 'string') {
      return data.value;
    }
  }
  return `${data}`;
}

export const W3CTextResult = ({data}: {data: W3C}) => {
  return <BinaryFileResultView data={data} />
}