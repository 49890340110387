import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { parseW3A } from "./FileResolveParser";
import { W3A } from "./dependency/W3A";

type W3ADropZoneProps = {
  onResponse: (val: W3A) => void;
}

export const W3ADropZone = ({onResponse}: W3ADropZoneProps) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    parseW3A(acceptedFiles[0])
      .then(response => onResponse(response));
  }, [onResponse])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} style={{
      border: '1px dashed grey'
    }}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}