import { useState, useCallback, useMemo } from "react";
import { W3Campaign } from 'backend-api';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { W3AResult } from "./W3AView";
import { CAMPAIGN } from "./dependency/CAMPAIGN";
import { CampaignDropZone } from "./CampaignDropZone";
import { W3FResult } from "./W3FView";
import { W3BResult } from "./W3BView";
import { W3DResult } from "./W3DView";
import { W3HResult } from "./W3HView";
import { W3QResult } from "./W3QView";
import { W3TResult } from "./W3TView";
import { W3UResult } from "./W3UView";
import { IMPResult } from "./IMPView";
import { WTSResult } from "./WTSView";
import { parseWTS } from "./FileResolveParser";
import { parseWts } from "./WtsParseUtil";
import { resolveWtsString } from "./ContextUtil";
import { MiscResult } from "./MiscView";
import { SkinResult } from "./SkinView";
//import { BlockContext } from "wts-parse";

export const CampaignView = () => {
  const [data, setData] = useState<CAMPAIGN>();

  const handleResponse = useCallback((response: CAMPAIGN) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      Campaign
      <CampaignDropZone onResponse={handleResponse} />
      {data && <CampaignResultView data={data} />}
    </div>
  );
}

const CampaignResultView = ({data: dataRaw}: {data: CAMPAIGN}) => {
  const data = dataRaw as W3Campaign & {
    wts: string;
  };
  const wtsTree = useMemo(() => data.wts ? parseWts(data.wts) : undefined, [data.wts]);
  const context = useMemo(() => ({resolveString: (str: string) => resolveWtsString(wtsTree, str)}), [wtsTree]);
  return <>
      <Tabs>
        <TabList style={{
          display: 'flex',
          margin: 0,
          marginBlock: 0,
          paddingInline: 0
        }}>
          {data.w3f && <Tab>
            W3F
          </Tab>}
          {data.w3u && <Tab>
            W3U
          </Tab>}
          {data.w3t && <Tab>
            W3T
          </Tab>}
          {data.w3b && <Tab>
            W3B
          </Tab>}
          {data.w3d && <Tab>
            W3D
          </Tab>}
          {data.w3a && <Tab>
            W3A
          </Tab>}
          {data.w3h && <Tab>
            W3H
          </Tab>}
          {data.w3q && <Tab>
            W3Q
          </Tab>}
          {data.imp && <Tab>
            IMP
          </Tab>}
          {data.wts && <Tab>
            WTS
          </Tab>}
          {data.misc && <Tab>
            Misc
          </Tab>}
          {data.skin && <Tab>
            Skin
          </Tab>}
        </TabList>
        {data.w3f && <TabPanel>
          <W3FResult data={data.w3f} context={context} />
        </TabPanel>}
        {data.w3u && <TabPanel>
          <W3UResult data={data.w3u} />
        </TabPanel>}
        {data.w3t && <TabPanel>
          <W3TResult data={data.w3t} />
        </TabPanel>}
        {data.w3b && <TabPanel>
          <W3BResult data={data.w3b} />
        </TabPanel>}
        {data.w3d && <TabPanel>
          <W3DResult data={data.w3d} />
        </TabPanel>}
        {data.w3a && <TabPanel>
          <W3AResult data={data.w3a} />
        </TabPanel>}
        {data.w3h && <TabPanel>
          <W3HResult data={data.w3h} />
        </TabPanel>}
        {data.w3q && <TabPanel>
          <W3QResult data={data.w3q} context={context} />
        </TabPanel>}
        {data.imp && <TabPanel>
          <IMPResult data={data.imp} />
        </TabPanel>}
        {data.wts && <TabPanel>
          <WTSResult data={data.wts} />
        </TabPanel>}
        {data.misc && <TabPanel>
          <MiscResult data={data.misc} />
        </TabPanel>}
        {data.skin && <TabPanel>
          <SkinResult data={data.skin} />
        </TabPanel>}
      </Tabs>
  </>
}