import { useState, useCallback } from "react";
import { SHDDropZone } from "./SHDDropZone";
import { SHD } from "./dependency/SHD";
import { W3Shd } from "backend-api";

export const SHDView = () => {
  const [data, setData] = useState<SHD>();

  const handleResponse = useCallback((response: SHD) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      SHD
      <SHDDropZone onResponse={handleResponse} />
      {data && <SHDResult data={data} width={512} height={512} />}
    </div>
  );
}

export const SHDResult = ({data: dataRaw, width, height}: {data: SHD; width: number; height: number}) => {
  const data = {
    ...dataRaw as W3Shd,
    width,
    height
  };

  const handleCanvasRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.shadowNode?.forEach((shadowNode, index) => {
          const x = index % data.width!;
          const y = index / data.width!;
          const red = ((shadowNode & 0xff) >> 7) * 255;
          const green = ((shadowNode & 0xff) >> 7) * 255;
          const blue = ((shadowNode & 0xff) >> 7) * 255;
          ctx.strokeStyle = `rgba(${red}, ${green}, ${blue}, 255)`;
          ctx.strokeRect(x, data.height! - y - 1, 1, 1);
        });
      }
    }
  };

  return <>
    <canvas ref={handleCanvasRefChange} width={data.width} height={data.height} style={{border: "1px solid black"}} />
  </>
}