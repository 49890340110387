import { Context } from "./Context";
import { parseWts } from "./WtsParseUtil";
import { RootContext } from "./dependency/wtsParser";

export const resolveWtsString = (wtsTree: RootContext | undefined, str: string): string => {
    if (str && typeof str === 'string' && wtsTree) {
        const match = str.match(/^TRIGSTR_(\d+)$/);
        if (match?.[0]) {
            const matchNum = parseInt(match[1]);
            const block = wtsTree.block_list().find(block => parseInt(block._num.text) === matchNum);
            //const block = ((wtsTree as any).block() as BlockContext[]).find(block => parseInt((block as any).num.text) === matchNum);
            if (block) {
                const fullText = block._str.text;
                const matchInner = fullText.match(/^[\s]*{[\s]*(.+)[\s]*}[\s]*$/);
                if (matchInner) {
                    const innerText = matchInner[1];
                    return innerText;
                }
                return fullText;
            }
        }
    }
    return str;
}

export const jsonStringifyWithContext = (data: any, context?: Context) => {
    if (data === undefined) {
        return '';
    }
    if (data === null) {
        return '';
    }
    return JSON.stringify(data, (key, value) => {
        value = reduceValueObject(value);
        if (context) {
            return context.resolveString(value);
        }
        return value;
    }, 2)
}

export const reduceValueObject = (value: any) => {
    if (value !== undefined && value !== null && typeof value === 'object') {
        const keys = Object.keys(value);
        if (keys.length === 1 && keys.find(key => key === 'value')) {
            value = value.value;
        }
    }
    return value;
}