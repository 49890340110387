import { useState, useCallback } from "react";
import { TGAView } from "./TGAView";
import { TGADropZone } from "./TGADropZone";
import { Tga } from "kaitai";

export const TGAPanel = () => {
  const [data, setData] = useState<Tga>();

  const handleResponse = useCallback((response: Tga) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      TGA
      <TGADropZone onResponse={handleResponse} />
      {data && <TGAView data={data} />}
    </div>
  );
}