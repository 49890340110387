import { CompactTable } from '@table-library/react-table-library/compact';
import { useTree } from '@table-library/react-table-library/tree';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import './tabs-style.css';
import { Table } from "@table-library/react-table-library";
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Context } from './Context';
import { Spreadsheet } from 'react-spreadsheet';
import { reduceValueObject } from './ContextUtil';

export const resolve = (val: any, context?: Context): ReactNode | undefined => {
  if (val === undefined || val === null) {
    return undefined;
  }
  if (typeof val === 'boolean') {
    return <span>{val + ': ' + renderBoolean(val)}</span>;
  }
  if (typeof val === 'number') {
    return <span>{val}</span>;
  }
  if (typeof val === 'string') {
    if (context) {
      val = context.resolveString(val)
    }
    return <span>{val}</span>;
  }
  if (Array.isArray(val)) {
    return <ArrayView arr={val} resolve={resolve} context={context} />
  }
  if (typeof val === 'object') {
    if (val.value !== undefined) {
      return resolve(val.value, context);
    }
    if (val.red !== undefined && val.green !== undefined && val.blue !== undefined && val.alpha !== undefined) {
      return renderColor(val);
    }
    return <ObjectView obj={val} resolve={resolve} context={context} />;
  }
};

export const ResolverView = ({val, context}: {val: object, context?: Context}) => {
  return <>{resolve(val, context)}</>;
};

const renderBoolean = (val: boolean | undefined) => {
  if (val) {
    return <span style={{
      color: 'green',
      fontWeight: 'bold'
    }}>{"\u2713"}</span>;
  }

  return <span style={{
    color: 'red',
    fontWeight: 'bold'
  }}>{"\u2716"}</span>;
};

export const ArrayView = ({arr, resolve, context, useSpreadSheet}: {arr: any[], resolve: (val: any, context?: Context) => ReactNode | undefined, context?: Context, useSpreadSheet?: boolean}) => {
  const effectiveArr = arr.map(element => {
      if (typeof element === 'object') {
          return element;
      }
      return {value: element};
  });

  if (useSpreadSheet) {
    return <SpreadSheetView effectiveArr={effectiveArr} context={context} /> 
  }

  return <CompactTableView effectiveArr={effectiveArr} context={context} /> 
};

const SpreadSheetView = ({effectiveArr, context}: {effectiveArr: any[], context?: Context}) => {
  const [applyContext, setApplyContext] = useState<boolean>(true);

  const headerSpreadSheetData = useMemo(() => effectiveArr[0] ? [Object.keys(effectiveArr[0]).map(key => {
    return {
      value: key
    };
  })] : undefined, [applyContext, effectiveArr]);
  const bodySpreadSheetData = useMemo(() => effectiveArr.map(el => Object.entries(el).map(entry => {
    let value = reduceValueObject(entry[1]);
    return {
      value: context && applyContext ? context?.resolveString(value) : value
    };
  })) ?? [], [applyContext, effectiveArr]);
  const spreadSheetData = useMemo(() => {
    if (headerSpreadSheetData) {
      return headerSpreadSheetData.concat(bodySpreadSheetData);
    }
    return bodySpreadSheetData;
  }, [applyContext, effectiveArr]);
  
  return <>
    <label htmlFor="applyContext">Resolve Context</label>
    <input id="applyContext" type="checkbox" checked={applyContext} onChange={(event) => setApplyContext(event.target.checked)} />
    <Spreadsheet data={spreadSheetData} />
  </>
}

const CompactTableView = ({effectiveArr, context}: {effectiveArr: any[], context?: Context}) => {
  type ElementNode = {
    id: string;
  } & any;
  
  const nodes: ElementNode[] | undefined = effectiveArr.map((element, index) => ({
    id: index.toString(),
    ...element
  })) ?? [];

  const COLUMNS = Object.keys(effectiveArr.length > 0 ? effectiveArr[0] : {})
    .map(key => ({
      label: key,
      renderCell: (element: ElementNode) => <React.Fragment key={key}>
        {resolve(element[key], context)}
      </React.Fragment>
    }));

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <>
    <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />
    {effectiveArr.map((element, index) => <Tooltip id={index.toString()} />)}
  </>;
}

const ObjectView = ({obj, resolve, context}: {obj: object, resolve: (val: any, context?: Context) => ReactNode | undefined, context?: Context}) => {
  return <>{Object.entries(obj).map(entry => <React.Fragment key={entry[0]}><span>{entry[0]}:</span>{resolve(entry[1], context)}</React.Fragment>)}</>;
}

const renderColor = ({red, green, blue, alpha}: {red?: number; green?: number; blue?: number; alpha?: number}) => {
  return <span style={{width: "0.75em", height: "0.75em", border: "2px solid black", backgroundColor: `rgb(${red ?? 0}, ${green ?? 0}, ${blue ?? 0})`, display: 'inline-block'}}></span>;
}