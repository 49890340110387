import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { parseWAI } from "./FileResolveParser";
import { W3Wai } from "backend-api";

type WAIDropZoneProps = {
  onResponse: (val: W3Wai) => void;
}

export const WAIDropZone = ({onResponse}: WAIDropZoneProps) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    parseWAI(acceptedFiles[0])
      .then(response => onResponse(response));
  }, [onResponse])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} style={{
      border: '1px dashed grey'
    }}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}