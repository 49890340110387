import { useMemo } from "react"
import { BinaryFileResultView } from "./BinaryFileResultView"
import { ArrayView, resolve } from "./ResolverView"
import { TabbedView } from "./TabbedView"
import React from "react"
import { W3id } from "backend-api"
import { Context } from "./Context"

type Obj = {
  baseId?: W3id;
  newId?: W3id;
  mod?: {
    id?: string;
    value?: any;
  }[];
}

interface ObjModTabbedViewProps {
    data: {
        originalObjectsChunk?: {
          obj?: Obj[];
        },
        customObjectsChunk?: {
          obj?: Obj[];
        }
    },
    context?: Context;
}

export const ObjModTabbedView = ({data, context}: ObjModTabbedViewProps) => {
    const reducedOriginalObjects = useMemo(() => data.originalObjectsChunk?.obj?.map(obj => {
      const copy = {...obj};
      delete (copy as any).mod;
      return copy as unknown as Omit<Obj, 'mod'>;
    }) ?? [], [data.originalObjectsChunk?.obj]);
    const reducedCustomObjects = useMemo(() => data.customObjectsChunk?.obj?.map(obj => {
      const copy = {...obj};
      delete (copy as any).mod;
      return copy as unknown as Omit<Obj, 'mod'>;
    }) ?? [], [data.customObjectsChunk?.obj]);

    const originalObjectsToMods = useMemo(() => data.originalObjectsChunk?.obj?.map(obj => {
      return {
        id: obj.baseId?.value,
        mod: obj.mod
      };
    }) ?? [], [data.originalObjectsChunk?.obj]);
    const customObjectsToMods = useMemo(() => data.customObjectsChunk?.obj?.map(obj => {
      return {
        id: obj.newId?.value,
        mod: obj.mod
      };
    }) ?? [], [data.customObjectsChunk?.obj]);
    const objectsToMods = useMemo(() => originalObjectsToMods.concat(customObjectsToMods), []);

    const tabs = [
      {
        label: 'Table',
        render: () => <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center'}}>
          Original Objects
          {data.originalObjectsChunk?.obj && <ArrayView arr={reducedOriginalObjects} resolve={resolve} context={context} useSpreadSheet={true} />}
          Custom Objects
          {data.customObjectsChunk?.obj && <ArrayView arr={reducedCustomObjects} resolve={resolve} context={context} useSpreadSheet={true} />}
          Mods
          {objectsToMods && objectsToMods.map(obj => <div key={obj.id} style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'center'}}>
              {obj.id}
              {obj.mod && <ArrayView arr={obj.mod} resolve={resolve} context={context} useSpreadSheet={true} />}
          </div>)}
        </div>
      },
      {
        label: 'All',
        render: () => <>
          <BinaryFileResultView data={data} />
        </>
      }
    ];
    return <TabbedView tabs={tabs} />
}