import { CommonTokenStream, CharStream } from 'antlr4';
//import { txtParser, txtLexer, RootContext } from 'txt-parse';
import txtLexer from './dependency/txtLexer';
import txtParser, { RootContext } from './dependency/txtParser';

export const parseTxt = (input: string): RootContext => {
    // if (input.charCodeAt(0) === 0xFEFF) {
    //     input = input.substr(1);
    // }
    // const lines = input.split('\n');
    // input = lines.map(line => {
    //     return line.replace(/\/\/.*/, '')
    // }).join('\n');
    const chars = new CharStream(input);
    const lexer = new txtLexer(chars);
    const tokens = new CommonTokenStream(lexer);
    const parser = new txtParser(tokens);
    //parser.buildParseTrees = true;
    const tree = parser.root();
    return tree;
}