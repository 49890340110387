"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readFile = exports.decrypt = exports.hash = void 0;
//var MpqDecrypt = require('./MpqDecrypt.js');
const Long = require('long');
const buffer_1 = require("buffer");
const zlib = require('react-zlib-js');
const bzip = require('seek-bzip');
const encryptionTable = (() => {
    // Prepare encryption table for MPQ hash function.
    let table = new Map();
    let seed = Long.fromValue(0x00100001, true);
    for (let i = 0; i < 256; i += 1) {
        let index = i;
        for (let j = 0; j < 5; j += 1) {
            seed = seed.mul(125).add(3).mod(0x2AAAAB);
            const t1 = seed.and(0xFFFF).shiftLeft(0x10);
            seed = seed.mul(125).add(3).mod(0x2AAAAB);
            const t2 = seed.and(0xFFFF);
            table.set(index, t1.or(t2).toNumber());
            index += 0x100;
        }
    }
    return table;
})();
var HashTypes;
(function (HashTypes) {
    HashTypes[HashTypes["TABLE_OFFSET"] = 0] = "TABLE_OFFSET";
    HashTypes[HashTypes["HASH_A"] = 1] = "HASH_A";
    HashTypes[HashTypes["HASH_B"] = 2] = "HASH_B";
    HashTypes[HashTypes["TABLE"] = 3] = "TABLE";
})(HashTypes || (HashTypes = {}));
;
const hash = (string, hashType) => {
    // Hash a string using MPQ's hash function.
    let seed1 = Long.fromValue(0x7FED7FED, true);
    let seed2 = Long.fromValue(0xEEEEEEEE, true);
    for (let ch of string.toUpperCase()) {
        let chNum = parseInt(ch, 10);
        //if (isNaN(chNum)) {
            chNum = ch.codePointAt(0);
        //}
        const value = Long.fromValue(encryptionTable.get((hashType << 8) + chNum), true);
        seed1 = value.xor(seed1.add(seed2)).and(0xFFFFFFFF);
        seed2 = seed1.add(seed2).add(chNum).add(seed2.shiftLeft(5)).add(3).and(0xFFFFFFFF);
    }
    return seed1.toNumber();
};
exports.hash = hash;
const decrypt = (data, key) => {
    // Decrypt hash or block table or a sector.
    const result = new buffer_1.Buffer(data.length);
    const ln = data.length / 4;
    let seed1 = Long.fromValue(key, true);
    let seed2 = Long.fromValue(0xEEEEEEEE, true);
    for (let i = 0; i < ln; i += 1) {
        seed2 = seed2.add(encryptionTable.get(0x400 + (seed1 & 0xFF)));
        seed2 = seed2.and(0xFFFFFFFF);
        let value = Long.fromValue(data.readUInt32LE(i * 4), true);
        value = value.xor(seed1.add(seed2)).and(0xFFFFFFFF);
        seed1 = seed1.xor(-1).shiftLeft(0x15).add(0x11111111).or(seed1.shiftRight(0x0B));
        seed1 = seed1.and(0xFFFFFFFF);
        seed2 = value.add(seed2).add(seed2.shiftLeft(5)).add(3).and(0xFFFFFFFF);
        result.writeUInt32LE(value.toNumber(), i * 4);
    }
    return result;
};
exports.decrypt = decrypt;
const getHashTableEntry = (hashTable, fileName) => {
    // Get the hash table entry corresponding to a given filename.
    const hashA = (0, exports.hash)(fileName, HashTypes.HASH_A);
    const hashB = (0, exports.hash)(fileName, HashTypes.HASH_B);
    for (const entry of hashTable.hashTableEntry) {
        if (entry.filePathHashA === hashA && entry.filePathHashB === hashB)
            return entry;
    }
};
const MPQ_FILE_IMPLODE = 0x00000100;
const MPQ_FILE_COMPRESS = 0x00000200;
const MPQ_FILE_ENCRYPTED = 0x00010000;
const MPQ_FILE_FIX_KEY = 0x00020000;
const MPQ_FILE_SINGLE_UNIT = 0x01000000;
const MPQ_FILE_DELETE_MARKER = 0x02000000;
const MPQ_FILE_SECTOR_CRC = 0x04000000;
const MPQ_FILE_EXISTS = 0x80000000;
const readFile = (mpqData, filename, forceDecompress) => {
    // Read a file from the MPQ archive.
    const decompress = (data) => {
        // Read the compression type and decompress file data.
        const compressionType = data.readUInt8(0);
        if (compressionType === 0)
            return data;
        else if (compressionType === 2)
            return zlib.unzipSync(data.slice(1));
        else if (compressionType === 16)
            return bzip.decode(data.slice(1));
        else
            throw new Error('Unsupported compression type.');
    };
    const hashEntry = getHashTableEntry(mpqData.hash, filename);
    if (!hashEntry) {
        return null;
    }
    const blockEntry = mpqData.block.blockTableEntry[hashEntry.fileBlockIndex];
    // Read the block.
    if (blockEntry.flags & MPQ_FILE_EXISTS) {
        if (blockEntry.blockSize === 0) {
            return null;
        }
        const offset = blockEntry.blockOffset + mpqData.headerOffset;
        let fileData = mpqData.bytes.slice(offset, offset + blockEntry.blockSize);
        let encrypted = false;
        let baseKey = 0;
        if (blockEntry.flags & MPQ_FILE_ENCRYPTED) {
            encrypted = true;
            const shortFileName = filename; // TODO
            baseKey = hash(shortFileName, HashTypes.TABLE);
            if (blockEntry.flags & MPQ_FILE_FIX_KEY) {
                baseKey = (baseKey + blockEntry.blockOffset) ^ blockEntry.fileSize;
            }
        }
        if (!(blockEntry.flags & MPQ_FILE_SINGLE_UNIT)) {
            // File consists of many sectors. They all need to be
            // decompressed separately and united.
            //throw new Error('Not yet implemented');

            const sectorSize = 512 << mpqData.header.sectorSizeShift;
            let sectors = Math.trunc(blockEntry.fileSize / sectorSize) + 1;
            let crc;
            if (blockEntry.flags & MPQ_FILE_SECTOR_CRC) {
                crc = true;
                sectors += 1;
            }
            else {
                crc = false;
            }
            let sectorsHeader = fileData.slice(0, (sectors + 1) * 4);
            if (encrypted) {
                sectorsHeader = decrypt(sectorsHeader, baseKey - 1);
            }
            const positions = [];
            for (let i = 0; i < (sectors + 1); i += 1) {
                positions[i] = sectorsHeader.readUInt32LE(i * 4);
            }
            const ln = positions.length - (crc ? 2 : 1);
            let result = new buffer_1.Buffer(0);
            let sectorBytesLeft = blockEntry.fileSize;
            for (let i = 0; i < ln; i += 1) {
                let sector = fileData.slice(positions[i], positions[i + 1]);
                if (encrypted) {
                    sector = decrypt(sector, baseKey + i);
                }
                if ((blockEntry.flags & MPQ_FILE_COMPRESS) && (forceDecompress || (sectorBytesLeft > sector.length))) {
                    sector = decompress(sector);
                }
                sectorBytesLeft -= sector.length;
                result = buffer_1.Buffer.concat([result, sector]);
            }
            fileData = result;
        }
        else {
            // Single unit files only need to be decompressed, but
            // compression only happens when at least one byte is gained.
            if ((blockEntry.flags & MPQ_FILE_COMPRESS) && (forceDecompress || (blockEntry.fileSize > blockEntry.blockSize))) {
                fileData = decrypt(fileData, baseKey);
                fileData = decompress(fileData);
            }
        }
        return fileData;
    }
    throw new Error('Not yet implemented');
};
exports.readFile = readFile;
