import { useState, useCallback } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { WAIDropZone } from "./WAIDropZone";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import yaml from "js-yaml";
import { W3Wai, W3WaiAttackGroup, W3WaiAttackWave, W3WaiBuildPriority, W3WaiCurrentGroup, W3WaiHarvestPriority, W3WaiPlayer, W3WaiTargetPriority } from 'backend-api';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTree } from '@table-library/react-table-library/tree';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import './tabs-style.css';
import { Table } from "@table-library/react-table-library";
import { WAI } from "./dependency/WAI";
import { jsonStringifyWithContext } from "./ContextUtil";
import { Context } from "./Context";
import { JSONView, YAMLView } from "./BinaryFileResultView";

export const WAIView = () => {
  const [data, setData] = useState<W3Wai>();

  const handleResponse = useCallback((response: W3Wai) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      WAI
      <WAIDropZone onResponse={handleResponse} />
      {/* {text && <pre style={{textAlign: 'left'}}>{JSON.stringify(JSON.parse(text), null, 2)}</pre>} */}
      {data && <WAIResult data={data} />}
    </div>
  );
}

type WAIResultProps = {
  data: W3Wai;
  context?: Context;
}

const WAIResult = ({data, context}: WAIResultProps) => {
  const wai = data;
  return (
    <Tabs>
      <TabList style={{
          display: 'flex',
          margin: 0,
          marginBlock: 0,
          paddingInline: 0
        }}
      >
        <Tab>JSON</Tab>
        <Tab>YAML</Tab>
        <Tab>Visual</Tab>
      </TabList>

      <TabPanel>
        <JSONView data={data} context={context} />
      </TabPanel>
      <TabPanel>
        <YAMLView data={data} context={context} />
      </TabPanel>
      <TabPanel>
        <div style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          textAlign: 'left',
          margin: '1em',
          gap: '0.5em'
        }}>
          <span>Name: {wai.name?.value}</span>
          <span>AI Race: {wai.race}</span>
          <FlagsTable wai={wai} />
          <PlayersTable wai={wai} />
          <AttackGroupsTable wai={wai} />
          <AttackWavesTable wai={wai} />
          <HarvestPriorityTable wai={wai} />
          <BuildPriorityTable wai={wai} />
          <TargetPriorityTable wai={wai} />
          <span>Base Building: {wai.baseBuilding?.value}</span>
          <span>First Hero: {wai.firstHero?.value}</span>
          <span>Second Hero: {wai.secondHero?.value}</span>
          <span>Third Hero: {wai.thirdHero?.value}</span>
          <span>Gold Worker: {wai.goldWorker?.value}</span>
          <span>Lumber Worker: {wai.lumberWorker?.value}</span>
          <span>Initial Delay: {wai.initialDelay}</span>
          <span>Map Path: {wai.mapPath?.value}</span>
          <span>Mine Building: {wai.mineBuilding?.value}</span>
          <span>Minimum Forces Attack Group Index: {wai.minimumForcesAttackGroupIndex}</span>
          <span>Regular Game Speed: {wai.regularGameSpeed}</span>
          <span>Repeat Waves: {wai.repeatWaves}</span>
        </div>
      </TabPanel>
    </Tabs>
  );
}

const FlagsTable = ({wai}: {wai: W3Wai}) => {
  type FlagNode = {
    id: string;
    name: string;
    value: boolean;
  };
  const nodes: FlagNode[] | undefined = Object.entries(wai.flags ?? []).map((entry, index) => ({
    id: index.toString(),
    name: entry[0],
    value: entry[1]
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Flag', renderCell: (flag: FlagNode) => flag.name },
    { label: 'Value', renderCell: (flag: FlagNode) => renderBoolean(flag.value) }
  ];

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const PlayersTable = ({wai}: {wai: W3Wai}) => {
  type PlayerNode = {
    id: string;
  } & W3WaiPlayer;
  const nodes: PlayerNode[] | undefined = wai.player?.map((player, index) => ({
    id: index.toString(),
    ...player
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Index', renderCell: (player: PlayerNode) => player.index },
    { label: 'Team Number', renderCell: (player: PlayerNode) => player.teamNumber },
    { label: 'Race', renderCell: (player: PlayerNode) => player.race },
    { label: 'Color', renderCell: (player: PlayerNode) => player.color },
    { label: 'Handicap', renderCell: (player: PlayerNode) => player.handicap },
    { label: 'AI', renderCell: (player: PlayerNode) => player.ai },
    { label: 'AI Difficulty', renderCell: (player: PlayerNode) => player.aiDifficulty },
    { label: 'AI Script Path', renderCell: (player: PlayerNode) => player.aiScriptPath?.value }
  ];

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const AttackGroupsTable = ({wai}: {wai: W3Wai}) => {
  type AttackGroupNode = {
    id: string;
  } & W3WaiAttackGroup;
  const nodes: AttackGroupNode[] | undefined = wai.attackGroup?.map((attackGroup, index) => ({
    id: index.toString(),
    ...attackGroup
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Index', renderCell: (attackGroup: AttackGroupNode) => attackGroup.index },
    { label: 'Name', renderCell: (attackGroup: AttackGroupNode) => attackGroup.name?.value },
    { label: 'Current Group', renderCell: (attackGroup: AttackGroupNode) => <CurrentGroupsTable attackGroup={attackGroup} /> }
  ];

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const CurrentGroupsTable = ({attackGroup}: {attackGroup: W3WaiAttackGroup}) => {
  type CurrentGroupNode = {
    id: string;
  } & W3WaiCurrentGroup;
  const nodes: CurrentGroupNode[] | undefined = attackGroup.currentGroup?.map((attackGroup, index) => ({
    id: index.toString(),
    ...attackGroup
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Unit', renderCell: (currentGroup: CurrentGroupNode) => currentGroup.unit?.value },
    { label: 'Quantity', renderCell: (currentGroup: CurrentGroupNode) => currentGroup.quantity },
    { label: 'Max Quantity', renderCell: (currentGroup: CurrentGroupNode) => currentGroup.maxQuantity },
    { label: 'Condition Index', renderCell: (currentGroup: CurrentGroupNode) => currentGroup.conditionIndex },
  ];

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const AttackWavesTable = ({wai}: {wai: W3Wai}) => {
  type AttackWaveNode = {
    id: string;
  } & W3WaiAttackWave;
  const nodes: AttackWaveNode[] | undefined = wai.attackWave?.map((player, index) => ({
    id: index.toString(),
    ...player
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Attack Group Index', renderCell: (attackWave: AttackWaveNode) => attackWave.attackGroupIndex },
    { label: 'Delay', renderCell: (attackWave: AttackWaveNode) => attackWave.delay },
  ];

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const HarvestPriorityTable = ({wai}: {wai: W3Wai}) => {
  type HarvestPriorityNode = {
    id: string;
  } & W3WaiHarvestPriority;
  const nodes: HarvestPriorityNode[] | undefined = wai.harvestPriority?.map((harvestPriority, index) => ({
    id: index.toString(),
    ...harvestPriority
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Workers', renderCell: (harvestPriority: HarvestPriorityNode) => harvestPriority.workers },
    { label: 'Harvest Type', renderCell: (harvestPriority: HarvestPriorityNode) => harvestPriority.harvestType },
    { label: 'Harvest Priority Type', renderCell: (harvestPriority: HarvestPriorityNode) => harvestPriority.harvestPriorityType },
    { label: 'Target', renderCell: (harvestPriority: HarvestPriorityNode) => harvestPriority.target },
    { label: 'Condition Index', renderCell: (harvestPriority: HarvestPriorityNode) => harvestPriority.conditionIndex },
  ];

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const BuildPriorityTable = ({wai}: {wai: W3Wai}) => {
  type BuildPriorityNode = {
    id: string;
  } & W3WaiBuildPriority;
  const nodes: BuildPriorityNode[] | undefined = wai.buildPriority?.map((harvestPriority, index) => ({
    id: index.toString(),
    ...harvestPriority
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Priority Type', renderCell: (buildPriority: BuildPriorityNode) => buildPriority.priorityType },
    { label: 'Tech Type', renderCell: (buildPriority: BuildPriorityNode) => buildPriority.techType },
    { label: 'Tech', renderCell: (buildPriority: BuildPriorityNode) => buildPriority.tech?.value },
    { label: 'Target', renderCell: (buildPriority: BuildPriorityNode) => buildPriority.target },
    { label: 'Condition Index', renderCell: (buildPriority: BuildPriorityNode) => buildPriority.conditionIndex },
  ];

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const TargetPriorityTable = ({wai}: {wai: W3Wai}) => {
  type TargetPriorityNode = {
    id: string;
  } & W3WaiTargetPriority;
  const nodes: TargetPriorityNode[] | undefined = wai.targetPriority?.map((targetPriority, index) => ({
    id: index.toString(),
    ...targetPriority
  })) ?? [];
  
  const COLUMNS = [
    { label: 'Priority Type', renderCell: (targetPriority: TargetPriorityNode) => targetPriority.priorityType },
    { label: 'Target', renderCell: (targetPriority: TargetPriorityNode) => targetPriority.target },
    { label: 'Creep Min Strength', renderCell: (targetPriority: TargetPriorityNode) => targetPriority.creepMinStrength },
    { label: 'Creep Max Strength', renderCell: (targetPriority: TargetPriorityNode) => targetPriority.creepMaxStrength },
    { label: 'Allow Flyers', renderCell: (targetPriority: TargetPriorityNode) => renderBoolean(!!targetPriority.allowFlyers) },
    { label: 'Condition Index', renderCell: (targetPriority: TargetPriorityNode) => targetPriority.conditionIndex },
  ];

  const theme = useTheme(getTheme());

  const data = {nodes};

  const tree = useTree(data, {
    state: {}
  });

  return <CompactTable columns={COLUMNS} data={data} tree={tree} theme={theme} />;
};

const renderBoolean = (val: boolean | undefined) => {
  if (val) {
    return <span style={{
      color: 'green',
      fontWeight: 'bold'
    }}>{"\u2713"}</span>;
  }

  return <span style={{
    color: 'red',
    fontWeight: 'bold'
  }}>{"\u2716"}</span>;
};

const renderColor = ({red, green, blue, alpha}: {red?: number; green?: number; blue?: number; alpha?: number}) => {
  return <span style={{marginLeft: "0.25em", width: "0.75em", height: "0.75em", border: "2px solid black", backgroundColor: `rgb(${red ?? 0}, ${green ?? 0}, ${blue ?? 0})`}}></span>;
}