import React from 'react';
import './App.css';
import { W3VView } from './W3VView';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { W3IView } from './W3IView';
import './tabs-style.css';
import { DooView } from './DooView';
import { DooUnitsView } from './DooUnitsView';
import { W3AView } from './W3AView';
import { W3CView } from './W3CView';
import { W3RView } from './W3RView';
import { W3SView } from './W3SView';
import { MMPView } from './MMPView';
import { SHDView } from './SHDView';
import { W3EView } from './W3EView';
import { WAIView } from './WAIView';
import { WCTView } from './WCTView';
import { WPMView } from './WPMView';
import { MPQView } from './MPQView';
import { BLPView } from './BLPView';
import { BLPPanel } from './BLPPanel';
import { SpecsPanel } from './SpecsPanel';
//import { ModelPanel } from './ModelPanel';
import { TGAPanel } from './TGAPanel';
import { MapView } from './MapView';
import { CampaignView } from './CampaignView';
import { W3FView } from './W3FView';
import { W3BView } from './W3BView';
import { W3HView } from './W3HView';
import { W3QView } from './W3QView';
import { W3TView } from './W3TView';
import { W3UView } from './W3UView';
import { W3DView } from './W3DView';
import { WGCView } from './WGCView';
import { WTGView } from './WTGView';
import { BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import { PJassView } from './PJassView';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainView />} />
        <Route path='/pjass' element={<PJassView />} />
      </Routes>
    </BrowserRouter>
  );
}

const MainView = () => {
  return <div className="App">
    <Tabs>
      <TabList style={{
        display: 'flex',
        margin: 0,
        marginBlock: 0,
        paddingInline: 0
      }}>
        <Tab>
          Map
        </Tab>
        <Tab>
          Campaign
        </Tab>
        <Tab>
          MPQ
        </Tab>
        <Tab>
          W3I
        </Tab>
        <Tab>
          W3V
        </Tab>
        <Tab>
          Doo
        </Tab>
        <Tab>
          DooUnits
        </Tab>
        <Tab>
          W3U
        </Tab>
        <Tab>
          W3T
        </Tab>
        <Tab>
          W3B
        </Tab>
        <Tab>
          W3D
        </Tab>
        <Tab>
          W3A
        </Tab>
        <Tab>
          W3H
        </Tab>
        <Tab>
          W3Q
        </Tab>
        <Tab>
          W3C
        </Tab>
        <Tab>
          W3R
        </Tab>
        <Tab>
          W3S
        </Tab>
        <Tab>
          MMP
        </Tab>
        <Tab>
          SHD
        </Tab>
        <Tab>
          WPM
        </Tab>
        <Tab>
          W3E
        </Tab>
        <Tab>
          WCT
        </Tab>
        <Tab>
          WAI
        </Tab>
        <Tab>
          W3F
        </Tab>
        <Tab>
          BLP
        </Tab>
        <Tab>
          TGA
        </Tab>
        <Tab>
          Model
        </Tab>
        <Tab>
          Specs
        </Tab>
        <Tab>
          WGC
        </Tab>
        <Tab>
          WTG
        </Tab>
      </TabList>
      <TabPanel>
        <MapView />
      </TabPanel>
      <TabPanel>
        <CampaignView />
      </TabPanel>
      <TabPanel>
        <MPQView />
      </TabPanel>
      <TabPanel>
        <W3IView />
      </TabPanel>
      <TabPanel>
        <W3VView />
      </TabPanel>
      <TabPanel>
        <DooView />
      </TabPanel>
      <TabPanel>
        <DooUnitsView />
      </TabPanel>
      <TabPanel>
        <W3UView />
      </TabPanel>
      <TabPanel>
        <W3TView />
      </TabPanel>
      <TabPanel>
        <W3BView />
      </TabPanel>
      <TabPanel>
        <W3DView />
      </TabPanel>
      <TabPanel>
        <W3AView />
      </TabPanel>
      <TabPanel>
        <W3HView />
      </TabPanel>
      <TabPanel>
        <W3QView />
      </TabPanel>
      <TabPanel>
        <W3CView />
      </TabPanel>
      <TabPanel>
        <W3RView />
      </TabPanel>
      <TabPanel>
        <W3SView />
      </TabPanel>
      <TabPanel>
        <MMPView />
      </TabPanel>
      <TabPanel>
        <SHDView />
      </TabPanel>
      <TabPanel>
        <WPMView />
      </TabPanel>
      <TabPanel>
        <W3EView />
      </TabPanel>
      <TabPanel>
        <WCTView />
      </TabPanel>
      <TabPanel>
        <WAIView />
      </TabPanel>
      <TabPanel>
        <W3FView />
      </TabPanel>
      <TabPanel>
        <BLPPanel />
      </TabPanel>
      <TabPanel>
        <TGAPanel />
      </TabPanel>
      <TabPanel>
        {/* <ModelPanel /> */}
      </TabPanel>
      <TabPanel>
        <SpecsPanel />
      </TabPanel>
      <TabPanel>
        <WGCView />
      </TabPanel>
      <TabPanel>
        <WTGView />
      </TabPanel>
    </Tabs>
  </div>
}

export default App;
