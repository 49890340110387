import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { parseMPQ } from "./FileResolveParser";
import { Mpq } from "mpq-utils";

type MPQDropZoneProps = {
  onResponse: (val: Mpq) => void;
}

export const MPQDropZone = ({onResponse}: MPQDropZoneProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    parseMPQ(acceptedFiles[0])
      .then(response => onResponse(response));
  }, [onResponse])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} style={{
      border: '1px dashed grey'
    }}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}