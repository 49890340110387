import { W3FileType, W3FileTypeFromFileName } from "./FileType"
import { Buffer } from 'buffer';
import { BACKEND_BASE_URL } from "./config";
import { WPM } from "./dependency/WPM";
import { WtgECA, WtgParam, WtgSubParam, Tga, W3Blp, W3Mpq, W3Wgc, W3Wtg131 } from "kaitai";
import { KaitaiStream } from "kaitai-struct";
import { Mpq } from "mpq-utils";
import { arrayBuffer } from "stream/consumers";
import { RootContext } from "./dependency/txtParser";
const wtgFuncLookup = require("WtgFuncLookup");

export const parseW3File = async (file: Buffer, filename: string) => {
    const type = W3FileTypeFromFileName(filename);

    switch (type) {
        case W3FileType.DOO: {
            return parseDoo(file);
        }
        case W3FileType.DOO_UNITS: {
            return parseDooUnits(file);
        }
        case W3FileType.SHD: {
            return parseSHD(file);
        }
        case W3FileType.WPM: {
            return parseWPM(file);
        }
        case W3FileType.W3U: {
            return parseW3U(file);
        }
        case W3FileType.W3T: {
            return parseW3T(file);
        }
        case W3FileType.W3B: {
            return parseW3B(file);
        }
        case W3FileType.W3D: {
            return parseW3D(file);
        }
        case W3FileType.W3A: {
            return parseW3A(file);
        }
        case W3FileType.W3H: {
            return parseW3H(file);
        }
        case W3FileType.W3Q: {
            return parseW3Q(file);
        }
        case W3FileType.W3E: {
            return parseW3E(file);
        }
        case W3FileType.W3C: {
            return parseW3C(file);
        }
        case W3FileType.W3R: {
            return parseW3R(file);
        }
        case W3FileType.W3S: {
            return parseW3S(file);
        }
        case W3FileType.W3I: {
            return parseW3I(file);
        }
        case W3FileType.WCT: {
            return parseWCT(file);
        }
        case W3FileType.MMP: {
            return parseMMP(file);
        }
        case W3FileType.MPQ: {
            //return parseMPQ(file);
            return file;
        }
        case W3FileType.WTS: {
            return parseWTS(file);
        }
        case W3FileType.J: {
            return {text: file.toString()};
        }
        case W3FileType.TXT: {
            return {text: file.toString()};
        }
        case W3FileType.JSON: {
            return JSON.parse(file.toString());
        }
        case W3FileType.BLP: {
            return parseBLP(file as unknown as File);
        }
        case W3FileType.TGA: {
            return parseTGA(file as unknown as File);
        }
        case W3FileType.W3F: {
            return parseW3F(file);
        }
    }
    throw new Error("no handler for " + W3FileType[type]);
}

export const parseDoo = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/doo', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseDooUnits = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/doo-units', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseSHD = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/shd', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseWPM = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/wpm', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response) as WPM);
}

export const parseW3U = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3u', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3T = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3u', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3B = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3u', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3D = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3u', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3A = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3a', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3H = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3u', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3Q = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3u', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3C = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3c', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3E = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3e', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3I = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3i', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3R = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3r', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3S = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3s', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3V = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3v', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseWAI = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/wai', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseWCT = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/wct', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseMMP = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/mmp', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

const bufferToArrayBuffer = (buf: Buffer) => {
    return buf.buffer.slice(buf.byteOffset, buf.byteOffset + buf.byteLength);
}

export const parseMPQ = async (file: File) => {
    const arrayBuffer = await file.arrayBuffer();
    const stream = new KaitaiStream(arrayBuffer);
    const w3mpq = new W3Mpq(stream);
    const hash = w3mpq.hash;

    const mpq: Mpq = {...w3mpq, hash: w3mpq.hash, block: w3mpq.block, headerOffset: 0, bytes: Buffer.from(arrayBuffer)};
    return mpq;
}

export const parseWTS = async (file: Buffer) => {
    return {text: file.toString()};
}

export const parseBLP = async (file: File) => {
    const arrayBuffer = await file.arrayBuffer();
    const stream = new KaitaiStream(arrayBuffer);
    const w3blp = new W3Blp(stream);
    return w3blp;
}

export const parseBLP2 = async (file: Buffer) => {
    //const bufo = require('bufo');
    const BLPFile = require('js-blp');

    // Check Bufo documentation for other ways to provide data beyond fs.
    const inp = await (file as unknown as File).arrayBuffer();
    const blp = new BLPFile(inp);

    // Metadata can be accessed after construction..
    console.info({
        "width": blp.width,
        "height": blp.height,
        "encoding": blp.encoding,
        "alphaEncoding": blp.alphaEncoding,
        "alphaDepth": blp.alphaDepth,
        "mipmapCount": blp.mapCount
    });

    // Data is requested for a specific mipmap..
    const data = blp.getPixels(0);
    return blp;
}

export const parseTGA = async (file: File) => {
    const arrayBuffer = await file.arrayBuffer();
    const stream = new KaitaiStream(arrayBuffer);
    const tga = new Tga(stream);
    return tga;
}

export const parseMap = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/map', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseCampaign = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/campaign', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseW3F = async (file: Buffer) => {
    return fetch(BACKEND_BASE_URL + '/w3f', {
        method: 'POST',
        body: file
      })
      .then(response => response.text())
      .then(response => JSON.parse(response));
}

export const parseWGC = async (file: File) => {
    const arrayBuffer = await file.arrayBuffer();
    const stream = new KaitaiStream(arrayBuffer);
    const w3wgc = new W3Wgc(stream);
    return w3wgc;
}

export const parseWTG = async (file: File, triggerData: RootContext) => {
    const arrayBuffer = await file.arrayBuffer();
    const stream = new KaitaiStream(arrayBuffer);
    const dict: Map<string, number> = new Map();

    triggerData.block_list().forEach(block => {
        const blockName = block._category.text;
        if (blockName === 'TriggerEvents') {
            block.line_list().forEach(line => {
                const key = line._key.text;
                if (!key.startsWith('_')) {
                    const values = line._values.map(token => token.text).filter(text => text !== 'nothing');
                    dict.set(key, values.length - 1);
                }
            });
        }
        if (blockName === 'TriggerConditions') {
            block.line_list().forEach(line => {
                const key = line._key.text;
                if (!key.startsWith('_')) {
                    const values = line._values.map(token => token.text).filter(text => text !== 'nothing');
                    dict.set(key, values.length - 1);
                }
            });
        }
        if (blockName === 'TriggerActions') {
            block.line_list().forEach(line => {
                const key = line._key.text;
                if (!key.startsWith('_')) {
                    const values = line._values.map(token => token.text).filter(text => text !== 'nothing');
                    dict.set(key, values.length - 1);
                }
            });
        }
        if (blockName === 'TriggerCalls') {
            block.line_list().forEach(line => {
                const key = line._key.text;
                if (!key.startsWith('_')) {
                    const values = line._values.map(token => token.text);
                    dict.set(key, values.length - 3);
                }
            });
        }
    });

    wtgFuncLookup.dict = dict;
    const wtg = new W3Wtg131(stream, dict);

    const fillParam = (param: WtgParam) => {
        if (param.subParam) {
            param.subParam.func.realNumParam = param.subParam.func.numParam;
            param.subParam.func.realName = param.subParam.func.name;
            param.subParam.param.forEach(childParam => {
                fillParam(childParam);
            })
        }
    }

    const fillEca = (eca: WtgECA) => {
        eca.func.realNumParam = eca.func.numParam;
        eca.func.realName = eca.func.name;
        eca.param.forEach(param => {
            fillParam(param);
        })
        eca.childEca?.forEach((child: WtgECA) => {
            fillEca(child);
        });
    }

    wtg.element.forEach(element => {
        element.trig?.eca.forEach(eca => {
            fillEca(eca);
        });
    });

    return wtg;
}