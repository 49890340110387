import SyntaxHighlighter from "react-syntax-highlighter";
import yaml from "js-yaml";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { ResolverView } from "./ResolverView";
import { useMemo, useState } from "react";
import { Context } from "./Context";
import { jsonStringifyWithContext } from "./ContextUtil";

type BinaryFileResultViewProps = {
    data: object;
    context?: Context;
}

const customStringify = function (v: any) {
  const cache = new Set();
  return JSON.stringify(v, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        // Circular reference found
        try {
          // If this value does not reference a parent it can be deduped
         return JSON.parse(JSON.stringify(value));
        }
        catch (err) {
          // discard key if value cannot be deduped
         return;
        }
      }
      // Store value in our set
      cache.add(value);
    }
    return value;
  });
};

const decycle = (dataRaw: object) => {
  return JSON.parse(JSON.stringify(dataRaw, (key, value) => {
    if (key.startsWith('_')) {
      return undefined;
    }
    return value;
  }));
}

export const BinaryFileResultView = ({data: dataRaw, context}: BinaryFileResultViewProps) => {
    const data: typeof dataRaw = useMemo(() => decycle(dataRaw) as unknown as BinaryFileResultViewProps['data'], [dataRaw]);
    return (
      <Tabs>
        <TabList style={{
            display: 'flex',
            margin: 0,
            marginBlock: 0,
            paddingInline: 0
          }}
        >
          <Tab>JSON</Tab>
          <Tab>YAML</Tab>
          <Tab>Visual</Tab>
        </TabList>
  
        <TabPanel>
          <JSONView data={data} context={context} />
        </TabPanel>
        <TabPanel>
          <YAMLView data={data} context={context} />
        </TabPanel>
        <TabPanel>
          <VisualView data={data} context={context} />
        </TabPanel>
      </Tabs>
    );
};

export const JSONView = ({data, context}: {data: any; context?: Context}) => {
  const [applyContext, setApplyContext] = useState<boolean>(true);

  const json = useMemo(() => jsonStringifyWithContext(data, applyContext ? context : undefined), [data, applyContext, context]);

  const highlighter = useMemo(() => {
    return <SyntaxHighlighter
      language="json"
      customStyle={{
        textAlign: 'left'
      }}
    >
      {json}
    </SyntaxHighlighter>
  }, [json]);

  return <>
    <label htmlFor="applyContext">Resolve Context</label>
    <input id="applyContext" type="checkbox" checked={applyContext} onChange={(event) => setApplyContext(event.target.checked)} />
    {highlighter}
  </>
}

export const YAMLView = ({data, context}: {data: any; context?: Context}) => {
  const [applyContext, setApplyContext] = useState<boolean>(true);
  return <>
    <label htmlFor="applyContext">Resolve Context</label>
    <input id="applyContext" type="checkbox" checked={applyContext} onChange={(event) => setApplyContext(event.target.checked)} />
    <SyntaxHighlighter
      language="yaml"
      customStyle={{
        textAlign: 'left'
      }}
    >
      {yaml.dump(jsonStringifyWithContext(data, applyContext ? context : undefined))}
    </SyntaxHighlighter>
  </>
}

export const VisualView = ({data, context}: {data: any; context?: Context}) => {
  const [applyContext, setApplyContext] = useState<boolean>(true);
  return <>
    <label htmlFor="applyContext">Resolve Context</label>
    <input id="applyContext" type="checkbox" checked={applyContext} onChange={(event) => setApplyContext(event.target.checked)} />
    <div style={{
        display: 'flex',
        flexFlow: 'column nowrap',
        textAlign: 'left',
        margin: '1em',
        gap: '0.5em'
    }}>
        <ResolverView val={data} context={applyContext ? context : undefined} />
    </div>
  </>
}