import { CommonTokenStream, CharStream } from 'antlr4';
//import { wtsParser, wtsLexer, RootContext } from 'wts-parse';
import wtsLexer from './dependency/wtsLexer';
import wtsParser, { RootContext } from './dependency/wtsParser';

export const parseWts = (input: string): RootContext => {
    if (input.charCodeAt(0) === 0xFEFF) {
        input = input.substr(1);
    }
    const lines = input.split('\n');
    input = lines.map(line => {
        return line.replace(/\/\/.*/, '')
    }).join('\n');
    const chars = new CharStream(input);
    const lexer = new wtsLexer(chars);
    const tokens = new CommonTokenStream(lexer);
    const parser = new wtsParser(tokens);
    //parser.buildParseTrees = true;
    const tree = parser.root();
    return tree;
}