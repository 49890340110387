import { useState, useCallback } from "react";
import { W3SDropZone } from "./W3SDropZone";
import { W3S } from "./dependency/W3S";
import { W3W3s } from "backend-api";
import { Spreadsheet } from "react-spreadsheet";

export const W3SView = () => {
  const [data, setData] = useState<W3S>();

  const handleResponse = useCallback((response: W3S) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3S
      <W3SDropZone onResponse={handleResponse} />
      {data && <W3SResult data={data} />}
    </div>
  );
}

export const W3SResult = ({data: dataRaw}: {data: W3S}) => {
  const data = dataRaw as W3W3s;
  const firstSound = data.sound?.[0];
  const fieldSheetData = [Object.entries(firstSound ?? {}).map(entry => ({value: `${entry[0]}`} ?? '')) ?? []];
  const objSheetData = data.sound?.map(sound => Object.entries(sound).map(entry => ({value: resolve(entry[1])} ?? '')) ?? []) ?? [];
  const sheetData = fieldSheetData.concat(objSheetData);
  return <Spreadsheet data={sheetData} />
}

const resolve = (data: any) => {
  if (data !== null && typeof data  === 'object') {
    if (typeof data.value === 'string') {
      return data.value;
    }
  }
  return `${data}`;
}