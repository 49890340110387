import { useState, useCallback } from "react";
import { W3TDropZone } from "./W3TDropZone";
import { W3T } from "./dependency/W3T";
import { W3W3t } from "backend-api";
import { ObjModTabbedView } from "./ObjModTabbedView";
import { Context } from "./Context";

export const W3TView = () => {
  const [data, setData] = useState<W3T>();

  const handleResponse = useCallback((response: W3T) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      W3T
      <W3TDropZone onResponse={handleResponse} />
      {data && <W3TResult data={data} />}
    </div>
  );
}

export const W3TResult = ({data: dataRaw, context}: {data: W3T, context?: Context}) => {
  const data = dataRaw as W3W3t;
  return <ObjModTabbedView data={data} context={context} />
}