// Generated from wts.g4 by ANTLR 4.13.0
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {
	ATN,
	ATNDeserializer, DecisionState, DFA, FailedPredicateException,
	RecognitionException, NoViableAltException, BailErrorStrategy,
	Parser, ParserATNSimulator,
	RuleContext, ParserRuleContext, PredictionMode, PredictionContextCache,
	TerminalNode, RuleNode,
	Token, TokenStream,
	Interval, IntervalSet
} from 'antlr4';
import wtsListener from "./wtsListener.js";
// for running tests with parameters, TODO: discuss strategy for typed parameters in CI
// eslint-disable-next-line no-unused-vars
type int = number;

export default class wtsParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly WS = 2;
	public static readonly NL = 3;
	public static readonly STR = 4;
	public static readonly INT = 5;
	public static readonly EOF = Token.EOF;
	public static readonly RULE_block = 0;
	public static readonly RULE_root = 1;
	public static readonly literalNames: (string | null)[] = [ null, "'STRING'" ];
	public static readonly symbolicNames: (string | null)[] = [ null, null, 
                                                             "WS", "NL", 
                                                             "STR", "INT" ];
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"block", "root",
	];
	public get grammarFileName(): string { return "wts.g4"; }
	public get literalNames(): (string | null)[] { return wtsParser.literalNames; }
	public get symbolicNames(): (string | null)[] { return wtsParser.symbolicNames; }
	public get ruleNames(): string[] { return wtsParser.ruleNames; }
	public get serializedATN(): number[] { return wtsParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(this, wtsParser._ATN, wtsParser.DecisionsToDFA, new PredictionContextCache());
	}
	// @RuleVersion(0)
	public block(): BlockContext {
		let localctx: BlockContext = new BlockContext(this, this._ctx, this.state);
		this.enterRule(localctx, 0, wtsParser.RULE_block);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 4;
			this.match(wtsParser.T__0);
			this.state = 6;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 5;
				this.match(wtsParser.WS);
				}
				}
				this.state = 8;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while (_la===2);
			{
			this.state = 10;
			localctx._num = this.match(wtsParser.INT);
			}
			this.state = 14;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===3) {
				{
				{
				this.state = 11;
				this.match(wtsParser.NL);
				}
				}
				this.state = 16;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			{
			this.state = 17;
			localctx._str = this.match(wtsParser.STR);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public root(): RootContext {
		let localctx: RootContext = new RootContext(this, this._ctx, this.state);
		this.enterRule(localctx, 2, wtsParser.RULE_root);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 22;
			this._errHandler.sync(this);
			_alt = this._interp.adaptivePredict(this._input, 2, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 19;
					_la = this._input.LA(1);
					if(!(_la===2 || _la===3)) {
					this._errHandler.recoverInline(this);
					}
					else {
						this._errHandler.reportMatch(this);
					    this.consume();
					}
					}
					}
				}
				this.state = 24;
				this._errHandler.sync(this);
				_alt = this._interp.adaptivePredict(this._input, 2, this._ctx);
			}
			this.state = 28;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===1) {
				{
				{
				this.state = 25;
				this.block();
				}
				}
				this.state = 30;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 34;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===2 || _la===3) {
				{
				{
				this.state = 31;
				_la = this._input.LA(1);
				if(!(_la===2 || _la===3)) {
				this._errHandler.recoverInline(this);
				}
				else {
					this._errHandler.reportMatch(this);
				    this.consume();
				}
				}
				}
				this.state = 36;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}

	public static readonly _serializedATN: number[] = [4,1,5,38,2,0,7,0,2,1,
	7,1,1,0,1,0,4,0,7,8,0,11,0,12,0,8,1,0,1,0,5,0,13,8,0,10,0,12,0,16,9,0,1,
	0,1,0,1,1,5,1,21,8,1,10,1,12,1,24,9,1,1,1,5,1,27,8,1,10,1,12,1,30,9,1,1,
	1,5,1,33,8,1,10,1,12,1,36,9,1,1,1,0,0,2,0,2,0,1,1,0,2,3,40,0,4,1,0,0,0,
	2,22,1,0,0,0,4,6,5,1,0,0,5,7,5,2,0,0,6,5,1,0,0,0,7,8,1,0,0,0,8,6,1,0,0,
	0,8,9,1,0,0,0,9,10,1,0,0,0,10,14,5,5,0,0,11,13,5,3,0,0,12,11,1,0,0,0,13,
	16,1,0,0,0,14,12,1,0,0,0,14,15,1,0,0,0,15,17,1,0,0,0,16,14,1,0,0,0,17,18,
	5,4,0,0,18,1,1,0,0,0,19,21,7,0,0,0,20,19,1,0,0,0,21,24,1,0,0,0,22,20,1,
	0,0,0,22,23,1,0,0,0,23,28,1,0,0,0,24,22,1,0,0,0,25,27,3,0,0,0,26,25,1,0,
	0,0,27,30,1,0,0,0,28,26,1,0,0,0,28,29,1,0,0,0,29,34,1,0,0,0,30,28,1,0,0,
	0,31,33,7,0,0,0,32,31,1,0,0,0,33,36,1,0,0,0,34,32,1,0,0,0,34,35,1,0,0,0,
	35,3,1,0,0,0,36,34,1,0,0,0,5,8,14,22,28,34];

	private static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!wtsParser.__ATN) {
			wtsParser.__ATN = new ATNDeserializer().deserialize(wtsParser._serializedATN);
		}

		return wtsParser.__ATN;
	}


	static DecisionsToDFA = wtsParser._ATN.decisionToState.map( (ds: DecisionState, index: number) => new DFA(ds, index) );

}

export class BlockContext extends ParserRuleContext {
	public _num!: Token;
	public _str!: Token;
	constructor(parser?: wtsParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public WS_list(): TerminalNode[] {
	    	return this.getTokens(wtsParser.WS);
	}
	public WS(i: number): TerminalNode {
		return this.getToken(wtsParser.WS, i);
	}
	public INT(): TerminalNode {
		return this.getToken(wtsParser.INT, 0);
	}
	public NL_list(): TerminalNode[] {
	    	return this.getTokens(wtsParser.NL);
	}
	public NL(i: number): TerminalNode {
		return this.getToken(wtsParser.NL, i);
	}
	public STR(): TerminalNode {
		return this.getToken(wtsParser.STR, 0);
	}
    public get ruleIndex(): number {
    	return wtsParser.RULE_block;
	}
	public enterRule(listener: wtsListener): void {
	    if(listener.enterBlock) {
	 		listener.enterBlock(this);
		}
	}
	public exitRule(listener: wtsListener): void {
	    if(listener.exitBlock) {
	 		listener.exitBlock(this);
		}
	}
}


export class RootContext extends ParserRuleContext {
	constructor(parser?: wtsParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public block_list(): BlockContext[] {
		return this.getTypedRuleContexts(BlockContext) as BlockContext[];
	}
	public block(i: number): BlockContext {
		return this.getTypedRuleContext(BlockContext, i) as BlockContext;
	}
	public WS_list(): TerminalNode[] {
	    	return this.getTokens(wtsParser.WS);
	}
	public WS(i: number): TerminalNode {
		return this.getToken(wtsParser.WS, i);
	}
	public NL_list(): TerminalNode[] {
	    	return this.getTokens(wtsParser.NL);
	}
	public NL(i: number): TerminalNode {
		return this.getToken(wtsParser.NL, i);
	}
    public get ruleIndex(): number {
    	return wtsParser.RULE_root;
	}
	public enterRule(listener: wtsListener): void {
	    if(listener.enterRoot) {
	 		listener.enterRoot(this);
		}
	}
	public exitRule(listener: wtsListener): void {
	    if(listener.exitRoot) {
	 		listener.exitRoot(this);
		}
	}
}
