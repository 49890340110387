import { useState, useCallback } from "react";
import { DooDropZone } from "./DooDropZone";
import { BinaryFileResultView } from "./BinaryFileResultView";
import { DOO } from "./dependency/DOO";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ArrayView, resolve } from "./ResolverView";
import { W3Doo } from "backend-api";

export const DooView = () => {
  const [data, setData] = useState<DOO>();

  const handleResponse = useCallback((response: DOO) => {
    setData(response);
  }, []);

  return (
    <div className="App">
      Doo
      <DooDropZone onResponse={handleResponse} />
      {data && <DOOResult data={data} width={512} height={512} offsetX={256} offsetY={256} />}
    </div>
  );
}

export const DOOResult = ({data: dataRaw, width, height, offsetX, offsetY}: {data: DOO; width: number; height: number, offsetX: number; offsetY: number}) => {
  const data = {
    ...dataRaw as W3Doo,
    width: width,
    height: height,
    offsetX,
    offsetY
  };

  const scale = 5;

  const handleCanvasRefChange = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {        
        data.doodad?.forEach((doodad) => {
          const x = (doodad.x! - offsetX) * scale / 128;
          const y = (doodad.y! - offsetY) * scale / 128;
          ctx.strokeStyle = `rgba(0, 255, 0, 255)`;
          ctx.strokeRect(x, data.height! * scale - y - 1, 1, 1);
        });
      }
    }
  };

  return <div style={{display: 'grid'}}>
    <div style={{display: 'flex', flexFlow: 'column nowrap', justifySelf: 'left', gridColumn: 1, gridRow: 1}}>
      Placements
      <canvas ref={handleCanvasRefChange} width={data.width * scale} height={data.height * scale} style={{border: "1px solid black"}} />
    </div>
  </div>
}

export const DooResult2 = ({data: dataRaw}: {data: DOO}) => {
  const data = dataRaw as W3Doo;
  return <Tabs>
    <TabList>
      <Tab>Table</Tab>
      <Tab>JSON</Tab>
    </TabList>
    <TabPanel>
      {data.numDoodad}
      -
      {data.numSpecialDoodad}
      {/* {data.doodad && <ArrayView arr={data.doodad} resolve={resolve} />} */}
      {/* {data.specialDoodad && <ArrayView arr={data.specialDoodad} resolve={resolve} />} */}
      {/*<BinaryFileResultView data={data} />*/}
    </TabPanel>
  </Tabs>
}