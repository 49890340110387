// Generated from wts.g4 by ANTLR 4.13.0
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import {
	ATN,
	ATNDeserializer,
	CharStream,
	DecisionState, DFA,
	Lexer,
	LexerATNSimulator,
	RuleContext,
	PredictionContextCache,
	Token
} from "antlr4";
export default class wtsLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly WS = 2;
	public static readonly NL = 3;
	public static readonly STR = 4;
	public static readonly INT = 5;
	public static readonly EOF = Token.EOF;

	public static readonly channelNames: string[] = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	public static readonly literalNames: (string | null)[] = [ null, "'STRING'" ];
	public static readonly symbolicNames: (string | null)[] = [ null, null, 
                                                             "WS", "NL", 
                                                             "STR", "INT" ];
	public static readonly modeNames: string[] = [ "DEFAULT_MODE", ];

	public static readonly ruleNames: string[] = [
		"T__0", "WS", "NL", "STR", "INT",
	];


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(this, wtsLexer._ATN, wtsLexer.DecisionsToDFA, new PredictionContextCache());
	}

	public get grammarFileName(): string { return "wts.g4"; }

	public get literalNames(): (string | null)[] { return wtsLexer.literalNames; }
	public get symbolicNames(): (string | null)[] { return wtsLexer.symbolicNames; }
	public get ruleNames(): string[] { return wtsLexer.ruleNames; }

	public get serializedATN(): number[] { return wtsLexer._serializedATN; }

	public get channelNames(): string[] { return wtsLexer.channelNames; }

	public get modeNames(): string[] { return wtsLexer.modeNames; }

	public static readonly _serializedATN: number[] = [4,0,5,55,6,-1,2,0,7,
	0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,1,4,1,20,
	8,1,11,1,12,1,21,1,2,4,2,25,8,2,11,2,12,2,26,1,2,1,2,1,3,1,3,5,3,33,8,3,
	10,3,12,3,36,9,3,1,3,4,3,39,8,3,11,3,12,3,40,1,3,5,3,44,8,3,10,3,12,3,47,
	9,3,1,3,1,3,1,4,4,4,52,8,4,11,4,12,4,53,0,0,5,1,1,3,2,5,3,7,4,9,5,1,0,4,
	2,0,9,9,32,32,2,0,10,10,13,13,1,0,125,125,1,0,48,57,60,0,1,1,0,0,0,0,3,
	1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,1,11,1,0,0,0,3,19,1,0,0,0,5,
	24,1,0,0,0,7,30,1,0,0,0,9,51,1,0,0,0,11,12,5,83,0,0,12,13,5,84,0,0,13,14,
	5,82,0,0,14,15,5,73,0,0,15,16,5,78,0,0,16,17,5,71,0,0,17,2,1,0,0,0,18,20,
	7,0,0,0,19,18,1,0,0,0,20,21,1,0,0,0,21,19,1,0,0,0,21,22,1,0,0,0,22,4,1,
	0,0,0,23,25,7,1,0,0,24,23,1,0,0,0,25,26,1,0,0,0,26,24,1,0,0,0,26,27,1,0,
	0,0,27,28,1,0,0,0,28,29,6,2,0,0,29,6,1,0,0,0,30,34,5,123,0,0,31,33,3,5,
	2,0,32,31,1,0,0,0,33,36,1,0,0,0,34,32,1,0,0,0,34,35,1,0,0,0,35,38,1,0,0,
	0,36,34,1,0,0,0,37,39,8,2,0,0,38,37,1,0,0,0,39,40,1,0,0,0,40,38,1,0,0,0,
	40,41,1,0,0,0,41,45,1,0,0,0,42,44,3,5,2,0,43,42,1,0,0,0,44,47,1,0,0,0,45,
	43,1,0,0,0,45,46,1,0,0,0,46,48,1,0,0,0,47,45,1,0,0,0,48,49,5,125,0,0,49,
	8,1,0,0,0,50,52,7,3,0,0,51,50,1,0,0,0,52,53,1,0,0,0,53,51,1,0,0,0,53,54,
	1,0,0,0,54,10,1,0,0,0,7,0,21,26,34,40,45,53,1,6,0,0];

	private static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!wtsLexer.__ATN) {
			wtsLexer.__ATN = new ATNDeserializer().deserialize(wtsLexer._serializedATN);
		}

		return wtsLexer.__ATN;
	}


	static DecisionsToDFA = wtsLexer._ATN.decisionToState.map( (ds: DecisionState, index: number) => new DFA(ds, index) );
}