// Generated from txt.g4 by ANTLR 4.13.0
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {
	ATN,
	ATNDeserializer, DecisionState, DFA, FailedPredicateException,
	RecognitionException, NoViableAltException, BailErrorStrategy,
	Parser, ParserATNSimulator,
	RuleContext, ParserRuleContext, PredictionMode, PredictionContextCache,
	TerminalNode, RuleNode,
	Token, TokenStream,
	Interval, IntervalSet
} from 'antlr4';
import txtListener from "./txtListener.js";
// for running tests with parameters, TODO: discuss strategy for typed parameters in CI
// eslint-disable-next-line no-unused-vars
type int = number;

export default class txtParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly COMMENT = 4;
	public static readonly NL = 5;
	public static readonly STR = 6;
	public static readonly COMMA = 7;
	public static readonly WS = 8;
	public static readonly EOF = Token.EOF;
	public static readonly RULE_block = 0;
	public static readonly RULE_line = 1;
	public static readonly RULE_root = 2;
	public static readonly literalNames: (string | null)[] = [ null, "'['", 
                                                            "']'", "'='", 
                                                            null, null, 
                                                            null, "','" ];
	public static readonly symbolicNames: (string | null)[] = [ null, null, 
                                                             null, null, 
                                                             "COMMENT", 
                                                             "NL", "STR", 
                                                             "COMMA", "WS" ];
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"block", "line", "root",
	];
	public get grammarFileName(): string { return "txt.g4"; }
	public get literalNames(): (string | null)[] { return txtParser.literalNames; }
	public get symbolicNames(): (string | null)[] { return txtParser.symbolicNames; }
	public get ruleNames(): string[] { return txtParser.ruleNames; }
	public get serializedATN(): number[] { return txtParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(this, txtParser._ATN, txtParser.DecisionsToDFA, new PredictionContextCache());
	}
	// @RuleVersion(0)
	public block(): BlockContext {
		let localctx: BlockContext = new BlockContext(this, this._ctx, this.state);
		this.enterRule(localctx, 0, txtParser.RULE_block);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 6;
			this.match(txtParser.T__0);
			this.state = 7;
			localctx._category = this.match(txtParser.STR);
			this.state = 8;
			this.match(txtParser.T__1);
			this.state = 12;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===8) {
				{
				{
				this.state = 9;
				this.match(txtParser.WS);
				}
				}
				this.state = 14;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 15;
			this.match(txtParser.NL);
			this.state = 19;
			this._errHandler.sync(this);
			_alt = this._interp.adaptivePredict(this._input, 1, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 16;
					_la = this._input.LA(1);
					if(!((((_la) & ~0x1F) === 0 && ((1 << _la) & 304) !== 0))) {
					this._errHandler.recoverInline(this);
					}
					else {
						this._errHandler.reportMatch(this);
					    this.consume();
					}
					}
					}
				}
				this.state = 21;
				this._errHandler.sync(this);
				_alt = this._interp.adaptivePredict(this._input, 1, this._ctx);
			}
			this.state = 32;
			this._errHandler.sync(this);
			_alt = this._interp.adaptivePredict(this._input, 3, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 22;
					this.match(txtParser.NL);
					this.state = 26;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 304) !== 0)) {
						{
						{
						this.state = 23;
						_la = this._input.LA(1);
						if(!((((_la) & ~0x1F) === 0 && ((1 << _la) & 304) !== 0))) {
						this._errHandler.recoverInline(this);
						}
						else {
							this._errHandler.reportMatch(this);
						    this.consume();
						}
						}
						}
						this.state = 28;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
					}
					this.state = 29;
					this.line();
					}
					}
				}
				this.state = 34;
				this._errHandler.sync(this);
				_alt = this._interp.adaptivePredict(this._input, 3, this._ctx);
			}
			this.state = 38;
			this._errHandler.sync(this);
			_alt = this._interp.adaptivePredict(this._input, 4, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 35;
					_la = this._input.LA(1);
					if(!((((_la) & ~0x1F) === 0 && ((1 << _la) & 304) !== 0))) {
					this._errHandler.recoverInline(this);
					}
					else {
						this._errHandler.reportMatch(this);
					    this.consume();
					}
					}
					}
				}
				this.state = 40;
				this._errHandler.sync(this);
				_alt = this._interp.adaptivePredict(this._input, 4, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public line(): LineContext {
		let localctx: LineContext = new LineContext(this, this._ctx, this.state);
		this.enterRule(localctx, 2, txtParser.RULE_line);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 41;
			localctx._key = this.match(txtParser.STR);
			this.state = 42;
			this.match(txtParser.T__2);
			this.state = 46;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===6) {
				{
				{
				this.state = 43;
				localctx._STR = this.match(txtParser.STR);
				localctx._values.push(localctx._STR);
				}
				}
				this.state = 48;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 52;
			this._errHandler.sync(this);
			_alt = this._interp.adaptivePredict(this._input, 6, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 49;
					this.match(txtParser.WS);
					}
					}
				}
				this.state = 54;
				this._errHandler.sync(this);
				_alt = this._interp.adaptivePredict(this._input, 6, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public root(): RootContext {
		let localctx: RootContext = new RootContext(this, this._ctx, this.state);
		this.enterRule(localctx, 4, txtParser.RULE_root);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 58;
			this._errHandler.sync(this);
			_alt = this._interp.adaptivePredict(this._input, 7, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 55;
					_la = this._input.LA(1);
					if(!((((_la) & ~0x1F) === 0 && ((1 << _la) & 304) !== 0))) {
					this._errHandler.recoverInline(this);
					}
					else {
						this._errHandler.reportMatch(this);
					    this.consume();
					}
					}
					}
				}
				this.state = 60;
				this._errHandler.sync(this);
				_alt = this._interp.adaptivePredict(this._input, 7, this._ctx);
			}
			this.state = 64;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===1) {
				{
				{
				this.state = 61;
				this.block();
				}
				}
				this.state = 66;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 70;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 304) !== 0)) {
				{
				{
				this.state = 67;
				_la = this._input.LA(1);
				if(!((((_la) & ~0x1F) === 0 && ((1 << _la) & 304) !== 0))) {
				this._errHandler.recoverInline(this);
				}
				else {
					this._errHandler.reportMatch(this);
				    this.consume();
				}
				}
				}
				this.state = 72;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}

	public static readonly _serializedATN: number[] = [4,1,8,74,2,0,7,0,2,1,
	7,1,2,2,7,2,1,0,1,0,1,0,1,0,5,0,11,8,0,10,0,12,0,14,9,0,1,0,1,0,5,0,18,
	8,0,10,0,12,0,21,9,0,1,0,1,0,5,0,25,8,0,10,0,12,0,28,9,0,1,0,5,0,31,8,0,
	10,0,12,0,34,9,0,1,0,5,0,37,8,0,10,0,12,0,40,9,0,1,1,1,1,1,1,5,1,45,8,1,
	10,1,12,1,48,9,1,1,1,5,1,51,8,1,10,1,12,1,54,9,1,1,2,5,2,57,8,2,10,2,12,
	2,60,9,2,1,2,5,2,63,8,2,10,2,12,2,66,9,2,1,2,5,2,69,8,2,10,2,12,2,72,9,
	2,1,2,0,0,3,0,2,4,0,1,2,0,4,5,8,8,80,0,6,1,0,0,0,2,41,1,0,0,0,4,58,1,0,
	0,0,6,7,5,1,0,0,7,8,5,6,0,0,8,12,5,2,0,0,9,11,5,8,0,0,10,9,1,0,0,0,11,14,
	1,0,0,0,12,10,1,0,0,0,12,13,1,0,0,0,13,15,1,0,0,0,14,12,1,0,0,0,15,19,5,
	5,0,0,16,18,7,0,0,0,17,16,1,0,0,0,18,21,1,0,0,0,19,17,1,0,0,0,19,20,1,0,
	0,0,20,32,1,0,0,0,21,19,1,0,0,0,22,26,5,5,0,0,23,25,7,0,0,0,24,23,1,0,0,
	0,25,28,1,0,0,0,26,24,1,0,0,0,26,27,1,0,0,0,27,29,1,0,0,0,28,26,1,0,0,0,
	29,31,3,2,1,0,30,22,1,0,0,0,31,34,1,0,0,0,32,30,1,0,0,0,32,33,1,0,0,0,33,
	38,1,0,0,0,34,32,1,0,0,0,35,37,7,0,0,0,36,35,1,0,0,0,37,40,1,0,0,0,38,36,
	1,0,0,0,38,39,1,0,0,0,39,1,1,0,0,0,40,38,1,0,0,0,41,42,5,6,0,0,42,46,5,
	3,0,0,43,45,5,6,0,0,44,43,1,0,0,0,45,48,1,0,0,0,46,44,1,0,0,0,46,47,1,0,
	0,0,47,52,1,0,0,0,48,46,1,0,0,0,49,51,5,8,0,0,50,49,1,0,0,0,51,54,1,0,0,
	0,52,50,1,0,0,0,52,53,1,0,0,0,53,3,1,0,0,0,54,52,1,0,0,0,55,57,7,0,0,0,
	56,55,1,0,0,0,57,60,1,0,0,0,58,56,1,0,0,0,58,59,1,0,0,0,59,64,1,0,0,0,60,
	58,1,0,0,0,61,63,3,0,0,0,62,61,1,0,0,0,63,66,1,0,0,0,64,62,1,0,0,0,64,65,
	1,0,0,0,65,70,1,0,0,0,66,64,1,0,0,0,67,69,7,0,0,0,68,67,1,0,0,0,69,72,1,
	0,0,0,70,68,1,0,0,0,70,71,1,0,0,0,71,5,1,0,0,0,72,70,1,0,0,0,10,12,19,26,
	32,38,46,52,58,64,70];

	private static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!txtParser.__ATN) {
			txtParser.__ATN = new ATNDeserializer().deserialize(txtParser._serializedATN);
		}

		return txtParser.__ATN;
	}


	static DecisionsToDFA = txtParser._ATN.decisionToState.map( (ds: DecisionState, index: number) => new DFA(ds, index) );

}

export class BlockContext extends ParserRuleContext {
	public _category!: Token;
	constructor(parser?: txtParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public NL_list(): TerminalNode[] {
	    	return this.getTokens(txtParser.NL);
	}
	public NL(i: number): TerminalNode {
		return this.getToken(txtParser.NL, i);
	}
	public STR(): TerminalNode {
		return this.getToken(txtParser.STR, 0);
	}
	public WS_list(): TerminalNode[] {
	    	return this.getTokens(txtParser.WS);
	}
	public WS(i: number): TerminalNode {
		return this.getToken(txtParser.WS, i);
	}
	public line_list(): LineContext[] {
		return this.getTypedRuleContexts(LineContext) as LineContext[];
	}
	public line(i: number): LineContext {
		return this.getTypedRuleContext(LineContext, i) as LineContext;
	}
	public COMMENT_list(): TerminalNode[] {
	    	return this.getTokens(txtParser.COMMENT);
	}
	public COMMENT(i: number): TerminalNode {
		return this.getToken(txtParser.COMMENT, i);
	}
    public get ruleIndex(): number {
    	return txtParser.RULE_block;
	}
	public enterRule(listener: txtListener): void {
	    if(listener.enterBlock) {
	 		listener.enterBlock(this);
		}
	}
	public exitRule(listener: txtListener): void {
	    if(listener.exitBlock) {
	 		listener.exitBlock(this);
		}
	}
}


export class LineContext extends ParserRuleContext {
	public _key!: Token;
	public _STR!: Token;
	public _values: Token[] = [];
	constructor(parser?: txtParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public STR_list(): TerminalNode[] {
	    	return this.getTokens(txtParser.STR);
	}
	public STR(i: number): TerminalNode {
		return this.getToken(txtParser.STR, i);
	}
	public WS_list(): TerminalNode[] {
	    	return this.getTokens(txtParser.WS);
	}
	public WS(i: number): TerminalNode {
		return this.getToken(txtParser.WS, i);
	}
    public get ruleIndex(): number {
    	return txtParser.RULE_line;
	}
	public enterRule(listener: txtListener): void {
	    if(listener.enterLine) {
	 		listener.enterLine(this);
		}
	}
	public exitRule(listener: txtListener): void {
	    if(listener.exitLine) {
	 		listener.exitLine(this);
		}
	}
}


export class RootContext extends ParserRuleContext {
	constructor(parser?: txtParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public block_list(): BlockContext[] {
		return this.getTypedRuleContexts(BlockContext) as BlockContext[];
	}
	public block(i: number): BlockContext {
		return this.getTypedRuleContext(BlockContext, i) as BlockContext;
	}
	public WS_list(): TerminalNode[] {
	    	return this.getTokens(txtParser.WS);
	}
	public WS(i: number): TerminalNode {
		return this.getToken(txtParser.WS, i);
	}
	public NL_list(): TerminalNode[] {
	    	return this.getTokens(txtParser.NL);
	}
	public NL(i: number): TerminalNode {
		return this.getToken(txtParser.NL, i);
	}
	public COMMENT_list(): TerminalNode[] {
	    	return this.getTokens(txtParser.COMMENT);
	}
	public COMMENT(i: number): TerminalNode {
		return this.getToken(txtParser.COMMENT, i);
	}
    public get ruleIndex(): number {
    	return txtParser.RULE_root;
	}
	public enterRule(listener: txtListener): void {
	    if(listener.enterRoot) {
	 		listener.enterRoot(this);
		}
	}
	public exitRule(listener: txtListener): void {
	    if(listener.exitRoot) {
	 		listener.exitRoot(this);
		}
	}
}
